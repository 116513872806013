<template>
    <!-- prettier-ignore -->
    <div id="calendar" v-if="!$root.isLoadingData" class="row no-gutters" style="margin-left:90px">
        <div class="col-12">
            <div id="calendar" class="calendar-view fc fc-unthemed fc-ltr">
                <div class="fc-toolbar fc-header-toolbar">
                    <div class="fc-left">
                        <button type="button"
                            class="fc-prev-button fc-button fc-state-default fc-corner-left fc-corner-right"
                            aria-label="prev" @click="goToPreviousMonth">
                            <svg class="arrow-left" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewbox="0 0 10.15 17.41">
                                <polyline points="9.45 16.7 1.41 8.74 9.38 0.7"
                                    style="fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px"></polyline>
                            </svg>
                        </button>
                    </div>
                    <div class="fc-right">
                        <button type="button"
                            class="fc-next-button fc-button fc-state-default fc-corner-left fc-corner-right"
                            aria-label="next" @click="goToNextMonth">
                            <svg class="arrow-right" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewbox="0 0 10.12 17.41">
                                <polyline points="0.71 0.71 8.71 8.71 0.71 16.71"
                                    style="fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px"></polyline>
                            </svg>
                        </button>
                    </div>
                    <div class="fc-center">
                        <h2>
                            {{ monthString + " " + year }}
                        </h2>
                    </div>
                    <div class="fc-clear"></div>
                </div>
                <div class="fc-view-container fc-view fc-month-view fc-basic-view">
                    <table class="">
                        <thead class="fc-head">
                            <tr>
                                <td class="fc-head-container fc-widget-header">
                                    <div class="fc-row fc-widget-header">
                                        <table class="">
                                            <thead>
                                                <tr>
                                                    <th class="fc-day-header fc-widget-header fc-mon">
                                                        <span>Monday</span>
                                                    </th>
                                                    <th class="fc-day-header fc-widget-header fc-tue">
                                                        <span>Tuesday</span>
                                                    </th>
                                                    <th class="fc-day-header fc-widget-header fc-wed">
                                                        <span>Wednesday</span>
                                                    </th>
                                                    <th class="fc-day-header fc-widget-header fc-thu">
                                                        <span>Thursday</span>
                                                    </th>
                                                    <th class="fc-day-header fc-widget-header fc-fri">
                                                        <span>Friday</span>
                                                    </th>
                                                    <th class="fc-day-header fc-widget-header fc-sat">
                                                        <span>Saturday</span>
                                                    </th>
                                                    <th class="fc-day-header fc-widget-header fc-sun">
                                                        <span>Sunday</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody class="fc-body">
                            <tr v-for="(row, r) in gridRows" :key="'Row' + r">
                                <td class="fc-widget-content">
                                    <div class="fc-scroller fc-day-grid-container fc-day-grid fc-unselectable c7">
                                        <div class="fc-row fc-week fc-widget-content c2">
                                            <!-- Row boxes -->
                                            <div class="fc-bg">
                                                <table class="">
                                                    <tbody>
                                                        <tr>
                                                            <td class="fc-day fc-widget-content fc-mon fc-past"></td>
                                                            <td class="fc-day fc-widget-content fc-tue fc-past"></td>
                                                            <td class="fc-day fc-widget-content fc-wed fc-past"></td>
                                                            <td class="fc-day fc-widget-content fc-thu fc-past"></td>
                                                            <td class="fc-day fc-widget-content fc-fri fc-past"></td>
                                                            <td class="fc-day fc-widget-content fc-sat fc-past"></td>
                                                            <td class="fc-day fc-widget-content fc-sun fc-past"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="fc-content-skeleton">
                                                <!-- Row box contents -->
                                                <table>
                                                    <thead>
                                                        <!-- Day numbers -->
                                                        <tr>
                                                            <td class="fc-day-top fc-mon fc-past"
                                                                v-for="(day, d) in row"
                                                                :key="'Row ' + r + 'DayHeader' + d">
                                                                <span class="fc-day-number">
                                                                    {{ day.dayNumber }}
                                                                    <span class="match-days-counter until"
                                                                        v-if="day.prevOffset">+{{ day.prevOffset
                                                                        }}</span>
                                                                    <span class="match-days-counter"
                                                                        v-if="day.nextOffset">-{{ day.nextOffset
                                                                        }}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <!-- Fixtures/Sessions -->
                                                        <tr>
                                                            <td class="fc-event-container" v-for="(day, d) in row"
                                                                :key="'Row ' + r + 'Day' + d">
                                                                <div v-for="(fixture, f) in day.fixtures"
                                                                    :key="'Fixture' + f">
                                                                    <div
                                                                        class="fc-day-grid-event fc-h-event fc-event fc-start fc-end pill match c1">
                                                                        <div class="fc-content">
                                                                            <span class="fc-title">{{
                                                                                    fixture.description
                                                                            }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div v-for="({ isLocked, sessionId, statusDisplayName, type, typeColour }, s) in day.sessions"
                                                                    :key="'Session' + s">
                                                                    <router-link v-if="!isLocked"
                                                                        :to="statusDisplayName === 'Complete' ? matchOverviewPath + customerId + '/' + sessionId : '#'"
                                                                        class="fc-day-grid-event fc-h-event fc-event fc-start fc-end pill Match c1"
                                                                        :style="{ borderColor: typeColour }">
                                                                        <div class="fc-content">
                                                                            <span class="fc-sub-title">{{ type }}</span>
                                                                        </div>

                                                                    </router-link>
                                                                    <span v-if="isLocked" class="fc-day-grid-event fc-h-event fc-event fc-start fc-end pill Match c1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                                                </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import DateUtils from "@/utils/DateUtils";

export default {
    data() {
        return {
            month: 0,
            year: 0,
            monthString: "",
            fixtures: null,
            sessions: null,
            previousFixture: null,
            nextFixture: null,
            gridRows: [],
            customerId: UserData.customerId(),
            matchOverviewPath: UserData.useReportingForSession() ? '/reporting/' : '/session/'
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => {});
        },
        buildGrid() {
            // Build the grid data from the sessions and fixtures objects
            this.gridRows = [];
            const monthStart = this.getMonthStart();

            // now loop through 6x7 days
            let dayOffset = 0;
            for (let r = 0; r < 6; r++) {
                const rowData = [];
                for (let d = 0; d < 7; d++) {
                    // add the day offset to the starting date to determine the day we are on
                    const thisDay = DateUtils.addDays(monthStart, dayOffset);
                    const dayString = new Date(thisDay).toUTCString(); // convert to string

                    const dayData = {
                        dayNumber: DateUtils.getDate(thisDay)
                    };

                    dayData.prevOffset = this.getNextFixtureOffset(thisDay) === 0 ? 0 : this.getPreviousFixtureOffset(thisDay);
                    dayData.nextOffset = this.getNextFixtureOffset(thisDay);

                    if (this.fixtures.length > 0) {
                        dayData.fixtures = this.fixtures.filter(function (
                            fixture
                        ) {
                            return new Date(fixture.date).toUTCString() === dayString;
                        });
                    }

                    if (this.sessions.length > 0) {
                        // we have sessions, so find the session data for this day
                        dayData.sessions = this.sessions
                            .filter(function (session) {
                                return new Date(session.date).toUTCString() === dayString;
                            })
                            .reverse();
                    }

                    rowData.push(dayData); // push the day data onto the row array                    
                    dayOffset++;
                }
                this.gridRows.push(rowData); // push the row onto the grid array
            }
        },
        async refreshData() {
            const monthStart = this.getMonthStart();
            // 42 days after the first visible date on this page
            const endDate = DateUtils.addDays(monthStart, 42);

            await this.$root.executeTaskWithProgressBar(async () => {
                await this.getFixtures(monthStart, endDate); // grab fixtures for the period
                await this.getSessions(monthStart, endDate); // grab sessions for the period
                this.buildGrid();
            });
        },
        async getFixtures(startDate, endDate) {
            // convert the start and end dates to ISO strings
            const urlStartDate = startDate.toISOString().substring(0, 10);
            const urlEndDate = endDate.toISOString().substring(0, 10);

            const response = await this.$root.webApiGet(
                `/fixtures?customerId=${this.customerId}&startDate=${urlStartDate}&endDate=${urlEndDate}`
            );

            if (response.status === 200) {
                this.fixtures = response.data.fixtures;
                this.previousFixture = response.data.previousFixtureDate === null ? null : new Date(response.data.previousFixtureDate);
                this.nextFixture = response.data.nextFixtureDate === null ? null : new Date(response.data.nextFixtureDate);
            } else {
                errorHandler.error(response, this);
            }
        },
        getPreviousFixtureOffset(currentDate) {
            // for a given date, return the offset to the previous fixture
            let prevFixtureDate;
            if (this.fixtures.length === 0) {
                prevFixtureDate = this.previousFixture;
            } else {
                if (currentDate < new Date(this.fixtures[0].date)) {
                    prevFixtureDate = this.previousFixture;
                } else {
                    for (let f = this.fixtures.length - 1;f >= 0;f--) {
                        const fixtureDate = new Date(this.fixtures[f].date);
                        if (fixtureDate <= currentDate) {
                            prevFixtureDate = fixtureDate;
                            break;
                        }
                    }
                }
            }
            if (prevFixtureDate) {
                const diffTime = Math.abs(currentDate - prevFixtureDate);
                return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            }
        },
        getNextFixtureOffset(currentDate) {
            // for a given date, return the offset to the next fixture
            let nextFixtureDate;
            if (this.fixtures.length === 0) {
                nextFixtureDate = this.nextFixture;
            } else if (
                currentDate >
                new Date(this.fixtures[this.fixtures.length - 1].date)
            ) {
                nextFixtureDate = this.nextFixture;
            } else {
                for (let f = 0;f < this.fixtures.length;f++) {
                    const fixtureDate = new Date(this.fixtures[f].date);
                    if (fixtureDate >= currentDate) {
                        nextFixtureDate = fixtureDate;
                        break;
                    }
                }
            }
            if (nextFixtureDate) {
                const diffDate = Math.abs(nextFixtureDate - currentDate);
                return Math.ceil(diffDate / (1000 * 60 * 60 * 24));
            }
        },
        async getSessions(startDate, endDate) {
            // convert the start and end dates to ISO strings
            const urlStartDate = startDate.toISOString().substring(0, 10);
            const urlEndDate = endDate.toISOString().substring(0, 10);

            const response = await this.$root.webApiGet(
                `/sessions?customerId=${this.customerId}&startDate=${urlStartDate}&endDate=${urlEndDate}`
            );

            if (response.status === 200) {
                this.sessions = response.data; // update the page session object
            } else {
                errorHandler.error(response, this);
            }
        },
        async goToPreviousMonth() {
            // go back one month
            this.month--;
            if (this.month === -1) {
                this.month = 11;
                this.year--;
            }
            this.monthString = DateUtils.getMonthString(this.month);
            window.sessionStorage.setItem(
                "sessionMonth",
                JSON.stringify(this.month)
            );
            window.sessionStorage.setItem(
                "sessionYear",
                JSON.stringify(this.year)
            );
            await this.refreshData();
        },
        async goToNextMonth() {
            // move forward one month
            this.month++;
            if (this.month === 12) {
                this.month = 0;
                this.year++;
            }
            this.monthString = DateUtils.getMonthString(this.month);
            window.sessionStorage.setItem(
                "sessionMonth",
                JSON.stringify(this.month)
            );
            window.sessionStorage.setItem(
                "sessionYear",
                JSON.stringify(this.year)
            );
            await this.refreshData();
        },
        getMonthStart() {
            let monthStart = new Date(Date.UTC(this.year, this.month, 1)); // first day of the month
            // shift start so that we pick up the previous Monday
            while (DateUtils.getDay(monthStart) !== 1) {
                monthStart = DateUtils.subtractDays(monthStart, 1);
            }
            return monthStart;
        },
    },
    async mounted() {
        console.log("Calendar.vue mounted");
        this.$root.newPageView("Calendar Page", UserData.userName());
        const sessionYear = JSON.parse(
            window.sessionStorage.getItem("sessionYear")
        );
        const sessionMonth = JSON.parse(
            window.sessionStorage.getItem("sessionMonth")
        );

        if (sessionYear) {
            this.year = sessionYear;
        } else {
            this.year = DateUtils.getYear(new Date());
        }

        if (sessionMonth) {
            this.month = sessionMonth;
            this.monthString = DateUtils.getMonthString(this.month);
        } else {
            this.month = DateUtils.getMonth(new Date());
            this.monthString = DateUtils.getMonthString(this.month);
        }

        await this.refreshData();
    }
};
</script>