<template>
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="margin-left:90px;">
        <PageHeader>
            <div class="d-flex justify-content-between">
                <PageHeaderTitle v-if="selectedPlayer">
                    <h2 class="player-name">
                        {{ selectedPlayer.name }}
                    </h2>
                    <span style="display:flex; align-items: baseline; height: auto">
                        <p style="margin-right: 5px">
                            {{ selectedPlayer.position }}
                        </p>
                    </span>
                </PageHeaderTitle>
                <div class="pt-4">
                    <DropdownSelector @item-selected="selectPlayer" :items="players" :getItemName="selectorDisplayText"
                        :index="selectedPlayerIdx" />
                </div>
            </div>
        </PageHeader>
        <MissingDataText v-if="!hasData" message="KPI Reporting Unavailable" />
        <div id="content" class="py-2 px-3" v-else>
            <div class="d-flex align-items-center justify-content-end">
                <RadioButton :id="'data-type-radio-group'" :modal="selectedDataType" :options="dataTypeOptions"
                    label="Data Type" :name="'kpi-options'" @update-modal="selectedDataType = $event"
                    :task="updatePlotData" />
            </div>
            <div class="px-3 mx-auto">
                <KPITable :data="data" columnKey="sessionId" headingKey="name" :isPlayerTable=false
                    :kpiGroups="kpiGroups" />
            </div>
        </div>
    </div>
</template>

<script>
import KPITable from '../Session/KPITable.vue';
import ReportingKpiGroups from '@/utils/ReportingKpiGroups';
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import { loadSelectedSquadIds } from "@/utils/Squad";

export default {
    components: {
        KPITable,
    },
    data() {
        return {
            customerId: UserData.customerId(),
            hasData: true,
            selectedDataOverviewType: 'total',
            dataOverviewOptions: [
                { text: 'Total', value: 'total' },
                { text: 'In Play', value: 'inPlay' },

            ],
            selectedDataType: 'data',
            dataTypeOptions: [
                { text: 'Data', value: 'data' },
                { text: 'Percentile', value: 'percentile' }
            ],
            players: [],
            selectedPlayerIdx: 0,
            data: [],
            kpiGroups: ReportingKpiGroups
        };
    },
    methods: {
        async getPlayers() {
            const response = await this.$root.webApiGet(
                `/players?customerId=${this.customerId}`
            );

            if (response.status === 200) {
                const sortPlayers = response.data.sort(
                    (a, b) =>
                        a.name.localeCompare(b.name)
                );

                const selectedSquads = loadSelectedSquadIds();
                const isActive = sortPlayers.sort((a, b) => b.isActive - a.isActive);
                this.players = isActive.filter(t => selectedSquads.includes(t.squadId));
            } else {
                errorHandler.error(response, this);
            }
        },
        async selectPlayer(p) {
            await this.$root.executeTaskWithProgressBar(async () => {
                this.selectedPlayerIdx = p;
                this.updatePlotData();
            });
        },
        selectorDisplayText(player) {
            return player.name;
        },
        updateTableData() {
            for (let i = 1; i <= 25; i++) {
                const dayDateFormatting = i < 10 ? '0' + i : i; //format dummy date to match date format in db
                this.data.push({
                    sessionId: i,
                    name: '2024-09-' + dayDateFormatting + ' (Opposition)',
                    matchTime: Math.floor(Math.random() * 91),
                    fatigueFlag: Math.floor(Math.random() * 3),
                    hamstringRisk: {
                        hamstringRisk: Math.random() >= 0.5,
                        hamstringRiskScore: (Math.random() * 5).toFixed(1)
                    },
                    gameDemandScore: Math.floor(Math.random() * 101),
                    distanceOver85WR: this.generateRandomKPI(),
                    distanceOver100WR: this.generateRandomKPI(),
                    distanceOver110WR: this.generateRandomKPI(),
                    distanceOver115WR: this.generateRandomKPI(),
                    distanceOver120WR: this.generateRandomKPI(),
                    totalDistance: this.generateRandomKPI(),
                    distanceOver17kmph: this.generateRandomKPI(),
                    distanceOver20kmph: this.generateRandomKPI(),
                    distanceOver23kmph: this.generateRandomKPI(),
                    distanceOver25_5kmph: this.generateRandomKPI(),
                    distanceOver27_5kmph: this.generateRandomKPI(),
                    distanceOver29kmph: this.generateRandomKPI(),
                    distanceOver30_5kmph: this.generateRandomKPI(),
                    distanceOver31_5kmph: this.generateRandomKPI(),
                    accelerationOver2mps2: this.generateRandomKPI(),
                    accelerationOver2_5mps2: this.generateRandomKPI(),
                    accelerationOver3mps2: this.generateRandomKPI(),
                    accelerationOver3_5mps2: this.generateRandomKPI(),
                    accelerationOver4mps2: this.generateRandomKPI(),
                    accelerationOver6mps2: this.generateRandomKPI(),
                    decelerationUnder2mps2: this.generateRandomKPI(),
                    decelerationUnder2_5mps2: this.generateRandomKPI(),
                    decelerationUnder3_25mps2: this.generateRandomKPI(),
                    decelerationUnder4mps2: this.generateRandomKPI(),
                    decelerationUnder4_75mps2: this.generateRandomKPI(),
                    decelerationUnder5_5mps2: this.generateRandomKPI(),
                });
            }
        },
        generateRandomKPI() {
            const maxValue = this.selectedDataType === 'percentile' ? 100 : 15000;
            return {
                value: Math.floor(Math.random() * maxValue),
                percentile: Math.floor(Math.random() * 101),
            };
        },
        updatePlotData() {
            this.data = [];
            this.updateTableData();
        }
    },
    computed: {
        selectedPlayer() {
            return this.players[this.selectedPlayerIdx];
        } 
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getPlayers();
            this.updateTableData();
            console.log("Reporting.vue mounted");
            this.$root.newPageView("Reporting Page", UserData.userName());
        });
    },
};
</script>