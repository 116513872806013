import Colours from "./Colours";

const gameDemand = gameDemandScore => {
    
    const value = gameDemandScore === null ? null : `${gameDemandScore}`;
    
	let colour;
    if (gameDemandScore === null) {
        colour = null;
    } else if (gameDemandScore >= 90) {
        colour = Colours.BRIGHT_GREEN;
    } else if (gameDemandScore <= 10) {
        colour = Colours.RED;
    } else {
        colour = Colours.SPORTLIGHT_TEAL;
    }

    return { colour, value };

};

export default gameDemand;
