<template>
    <b-form-group :label=label v-slot="{ ariaDescribedby }">
        <b-form-radio-group :id="id" v-model="radioGroupValue" :options="options" :aria-describedby="ariaDescribedby"
            :name=name button-variant="outline-primary" buttons @input="updateModal">
        </b-form-radio-group>
    </b-form-group>
</template>

<script>
export default {
    props: ['id', 'modal', 'options', 'label', 'name', 'task'],
    data() {
        return {
            radioGroupValue: this.modal
        };
    },
    watch: {
        modal(newVal) {
            this.radioGroupValue = newVal;
        }
    },
    methods: {
        updateModal() {
            this.$emit('update-modal', this.radioGroupValue);

            if (this.task) {
                this.task();
            }
        }
    }
};
</script>