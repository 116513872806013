class SquadOption {
	squadId;
	text;
	value;
	description;
	checked;

	constructor(squadId, description) {
		this.squadId = squadId;
		this.value = squadId;
		this.text = description;
		this.description = description;
		this.checked = SquadManager.isChecked(squadId);
	}
}

class SquadManager {
	static DEFAULT_SQUAD_ID = 1;

	static SQUADS = [
		new SquadOption(1, "1st Team"),
		new SquadOption(5, "U21s"),
		new SquadOption(6, "U18s"),
		new SquadOption(2, "Academy"),
		new SquadOption(3, "Historical"),
	];

	static isChecked(squadId) {
		return squadId === SquadManager.DEFAULT_SQUAD_ID;
	}

	static getSquadDescription(squadId) {
		const squad = SquadManager.SQUADS.find((s) => s.squadId === squadId);
		return squad ? squad.text : null;
	}

	static storeSelectedSquadIds(squadIds) {
		localStorage.setItem("squads", JSON.stringify(squadIds));
	}

	static loadSelectedSquadIds() {
		return JSON.parse(localStorage.getItem("squads"));
	}
}

export const {
    DEFAULT_SQUAD_ID,
	SQUADS,
	loadSelectedSquadIds,
	storeSelectedSquadIds,
	getSquadDescription,
} = SquadManager;