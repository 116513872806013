<template>
    <div id="content-wrap" style="margin-left:90px" :style="{opacity: !$root.isLoadingData ? 1 : 0}">
        <MissingDataText v-if="players.length === 0" :message="'Selected squad is empty'"/>
        <MissingDataText v-else-if="selectedPlayerIdx === -1" :message="'Player not found'"/>
        <div v-else>
            <PageHeader>
                <div class="d-flex justify-content-between">
                    <PageHeaderTitle v-if="players.length > 0">
                        <h2 class="player-name">
                            {{ players[this.selectedPlayerIdx].name }}
                        </h2>
                        <span style="display:flex; align-items: baseline; height: auto">
                            <p style="margin-right: 5px">
                                <template v-if="useShirtNumber">
                                    <ShirtNumberDisplay :shirtNumber="players[selectedPlayerIdx].shirtNumber"/>
                                </template>
                                <template v-else>
                                    {{ getSquadDescription(players[selectedPlayerIdx].squadId) + ', ' + players[selectedPlayerIdx].position }}
                                </template>
                            </p>
                            <EditPlayer v-bind:player="players[this.selectedPlayerIdx]" :refreshData="getPlayers" />
                        </span>
                    </PageHeaderTitle>
                    <div class="pt-4">
                        <b-dropdown v-if="players" id="player-dropdown"
                            :text="players.length > 0 ? players[this.selectedPlayerIdx].name : ''" right
                            variant="outline-primary">
                            <b-dropdown-item v-for="(player, p) in players" v-bind:key="p" @click="selectPlayer(p)"
                                :class="['pt-2', {'disabled': !UserData.hasPlayerModule(player.playerId)}]"
                                :aria-disabled="!UserData.hasPlayerModule(player.playerId)"
                                :tabindex="UserData.hasPlayerModule(player.playerId) ? '' : -1">
                                {{ player.name }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </PageHeader>
            <div class="full-width-radio-button-container" v-if="!hasAllSessionsAsDefault">
                <RadioButton v-if="sessionTypeSelected" :id="'sessions-radio-group'" :modal="sessionTypeSelected"
                    :options="sessionTypeOptions" :label="'Select Session Type'" :name="'sessions-radio-options'"
                    @update-modal="sessionTypeSelected = $event" :task="refreshData" />
            </div>
            <MissingDataText v-if="playerDataUnavailable" :message="'Player Data Unavailable'"/>
            <div v-else>
                <MissingDataText v-if="!hasTurnData" :message="'Player turns unavailable'" />
                <div class="player-turn-container" v-else>
                    <div>
                        <div class="player-turn-scatters">
                            <div class="turns-filter-description-container">
                                <span style="margin-top:0.7vh; margin-right:0.3vw; white-space: nowrap;">
                                    <Button :id="'add-button'" :type="'primary'" :title="'Filter Scatters'"
                                        v-b-modal.scatter-edit-modal :onClick="filter" />
                                </span>
                                <div class="display-labels">
                                    <p v-if="hasSetDateRange" class="turns-filter-description"> {{ dateRange }} </p>
                                    <p v-for="(label, index) in speedLabels" :key="'speed-' + index" class="turns-filter-description">
                                        Entry speed: {{ label }}
                                    </p>
                                    <p v-for="(label, index) in angleLabels" :key="'angle-' + index" class="turns-filter-description">
                                        Angle category: {{ label }}
                                    </p>
                                    <p v-for="(label, index) in accelerationsLabels" :key="'accelerations-' + index" class="turns-filter-description">
                                        {{ label }}
                                    </p>
                                    <p v-for="(label, index) in decelerationsLabels" :key="'decelerations-' + index" class="turns-filter-description">
                                        {{ label }}
                                    </p>
                                    <p></p>
                                </div>
                            </div>
                            <EditModal :id="'scatter-edit-modal'" :title="'Filter Scatter Plots'"
                                :okDisabled=disableFilterButton :ok="applyFilter" :cancel="clearFilter"
                                :cancelTitle="'Reset'" :okTitle="'Apply Filter'" :cancelVariant="'danger standard-btn'" size="lg">
                                <b-form-checkbox style="position: absolute; right: 0.2vw; top: 0"
                                    v-model="longitudinalView" name="check-button" button-variant="outline-primary"
                                    button>
                                    Longitudinal View<b> ({{ longitudinalView }})</b>
                                </b-form-checkbox>
                                <vc-date-picker v-model="range" :firstDayOfWeek=1 :max-date='new Date()' is-range
                                    color="teal" is-dark
                                    style="background-color:var(--bg-primary); border: 1px solid var(--sportlight-teal); border-radius: 0;" />
                                <br>
                                <RadioButton :id="'season-radio-group'" :modal="seasonSelected.text"
                                    :options="seasonOptions" :label="'Select season'" :name="'season-radio-options'"
                                    @update-modal="seasonSelected = lookupSeason($event)" />
                                <div style="padding:0">
                                    <b-form-group label="Entry speed category:" v-slot="{ ariaDescribedby }">
                                        <b-form-checkbox-group id="checkbox-group-1" v-model="speedSelected"
                                            :options="speedOptions" :aria-describedby="ariaDescribedby"
                                            name="radio-options" plain></b-form-checkbox-group>
                                    </b-form-group>

                                    <b-form-group label="Acceleration class:" v-slot="{ ariaDescribedby }">
                                        <b-form-checkbox-group id="checkbox-group-2" v-model="accelerationsSelected"
                                            :options="accelerationsOptions" :aria-describedby="ariaDescribedby"
                                            name="radio-options" plain></b-form-checkbox-group>
                                    </b-form-group>

                                    <b-form-group label="Deceleration class:" v-slot="{ ariaDescribedby }">
                                        <b-form-checkbox-group id="checkbox-group-3" v-model="decelerationsSelected"
                                            :options="decelerationsOptions" :aria-describedby="ariaDescribedby"
                                            name="radio-options" plain></b-form-checkbox-group>
                                    </b-form-group>

                                    <b-form-group label="Turn time:" v-slot="{ ariaDescribedby }">
                                        <b-form-checkbox-group id="checkbox-group-4" :options="timeOptions"
                                            v-model="timesSelected" :aria-describedby="ariaDescribedby"
                                            name="radio-options" plain></b-form-checkbox-group>
                                    </b-form-group>

                                    <b-form-group label="Turn angle:" v-slot="{ ariaDescribedby }">
                                        <b-form-checkbox-group id="checkbox-group-5" v-model="anglesSelected"
                                            :options="angleOptions" :aria-describedby="ariaDescribedby"
                                            name="radio-options" plain @input="disableAngle"></b-form-checkbox-group>
                                    </b-form-group>

                                </div>
                            </EditModal>
                            <div>
                                <div class="row">
                                    <p class="text-center col">All Turns: {{totalTurnCount}} (L:
                                        {{leftTurnsPercentage}}% R: {{rightTurnsPercentage}}%)</p>
                                </div>
                                <div class="row">
                                    <span class="float-left col mb-2">
                                        Left Side Turns
                                    </span>
                                    <TurnScattersLegend class="text-center col2" />
                                    <div class="col"></div>
                                </div>
                                <div class="row">
                                    <TurnScattersChart id="left-chart" class="col" style="height: 28vh"
                                        :turns="this.scatterTurnsLeft"
                                        :additionalTooltipText="this.turnsScattersAdditionalTooltipText"
                                        :minSpeed="this.minSpeed" :maxSpeed="this.maxSpeed" :displayYAxis="true"
                                        :maintainAspectRatio="false" :minAngle="this.minAngle"
                                        :maxAngle="this.maxAngle" />
                                </div>
                                <div class="row">
                                    <span class="float-left col mb-2">
                                        Right Side Turns
                                    </span>
                                </div>
                                <div class="row">
                                    <TurnScattersChart id="right-chart" class="col" style="height: 28vh"
                                        :turns="this.scatterTurnsRight"
                                        :additionalTooltipText="this.turnsScattersAdditionalTooltipText"
                                        :minSpeed="this.minSpeed" :maxSpeed="this.maxSpeed" :displayYAxis="true"
                                        :maintainAspectRatio="false" :minAngle="this.minAngle"
                                        :maxAngle="this.maxAngle" />
                                </div>
                                <div class="row">
                                    <div class="col"></div>
                                    <p class="text-center pt-0 mt-0 col2" style="font-size: 12px; height: 5vh;">Entry
                                        speed (<MeasurementUnit :type="Speed" />)
                                    </p>
                                    <div class="col"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="padding-top: 1vh">
                        <RadioButton :id="'turn-side-radio-group'" :modal="selectedTurnSide" :options="turnSideOptions"
                            :name="'turn-options'" @update-modal="selectedTurnSide = $event" :task="getTurnRates" />
                        <div style="margin-top: 2vh; width: 44vw; height: 60vh;">
                            <div style="text-align: center;">
                                <RadioButton :id="'turn-radio-group'" :modal="selectedTurnClass"
                                    :options="turnClassOptions" :label="''" :name="'season-radio-options'"
                                    @update-modal="selectedTurnClass = $event" :task="updateTurnClass" />
                            </div>
                            <div style="height: 60vh; overflow-y:auto; scrollbar-width: none;">
                                <div style="height: 60vh; position: relative;" v-if="turnRatesData">
                                    <TurnRatesPlot width="100%" :viewport="viewport" id="turn-rates-chart"
                                        :data="turnRatesData" title="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{'turn-kpi-summary-container': hasTurnData && hasKpiSummaryData}"
                    v-if="hasTurnData && hasKpiSummaryData">
                    <div class="player-total-turns" v-if="hasTurnData">
                        <canvas id="bar-chart" height="100%"></canvas>
                    </div>
                    <div class="kpi-summary" v-if="hasKpiSummaryData">
                        <RadioButton :id="'time-radio-group'" :modal="selectedMatchTime" :options="matchTimeOptions"
                            :name="'time-options'" @update-modal="selectedMatchTime = $event" :task="getKpiSummary" />
                        <table>
                            <thead>
                                <tr>
                                    <td>Metric</td>
                                    <td>Average</td>
                                    <td>Maximum</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Turns </td>
                                    <td>{{ turnData.average || "-" }}</td>
                                    <td>{{ turnData.maximum || "-" }}</td>
                                </tr>
                                <tr>
                                    <td>Distance(<MeasurementUnit :type="LongDistance" />)</td>
                                    <td>
                                        <MeasurementValue :type="LongDistance" :value="distanceData.average"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                    <td>
                                        <MeasurementValue :type="LongDistance" :value="distanceData.maximum"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>HSR(<MeasurementUnit :type="ShortDistance" />)</td>
                                    <td>
                                        <MeasurementValue :type="ShortDistance" :value="hsrData.average"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                    <td>
                                        <MeasurementValue :type="ShortDistance" :value="hsrData.maximum"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sprint Distance(<MeasurementUnit :type="ShortDistance" />)</td>
                                    <td>
                                        <MeasurementValue :type="ShortDistance" :value="sprintDistanceData.average"
                                            :format="formatStandard" nullString="-" />
                                    </td>
                                    <td>
                                        <MeasurementValue :type="ShortDistance" :value="sprintDistanceData.maximum"
                                            :format="formatStandard" nullString="-" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Peak Speed(<MeasurementUnit :type="PeakSpeed" />)</td>
                                    <td>
                                        <MeasurementValue :type="PeakSpeed" :value="peakSpeedData.average"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                    <td>
                                        <MeasurementValue :type="PeakSpeed" :value="peakSpeedData.maximum"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Acceleration Distance(<MeasurementUnit :type="ShortDistance" />)</td>
                                    <td>
                                        <MeasurementValue :type="ShortDistance"
                                            :value="accelerationDistanceData.average" :format="formatFine"
                                            nullString="-" />
                                    </td>
                                    <td>
                                        <MeasurementValue :type="ShortDistance"
                                            :value="accelerationDistanceData.maximum" :format="formatFine"
                                            nullString="-" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Medium + High Accelerations(N)</td>
                                    <td>{{ mediumHighAccelerationsData.average || "-" }}</td>
                                    <td>{{ mediumHighAccelerationsData.maximum || "-" }}</td>
                                </tr>
                                <tr>
                                    <td>Medium + High Decelerations(N)</td>
                                    <td>{{ mediumHighDecelerationsData.average || "-" }}</td>
                                    <td>{{ mediumHighDecelerationsData.maximum || "-" }}</td>
                                </tr>
                                <tr>
                                    <td>Peak Acceleration(<MeasurementUnit :type="Acceleration" />)</td>
                                    <td>
                                        <MeasurementValue :type="Acceleration" :value="topAccelerationPercentileData.average"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                    <td>
                                        <MeasurementValue :type="Acceleration" :value="topAccelerationPercentileData.maximum"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Peak Deceleration(<MeasurementUnit :type="Acceleration" />)</td>
                                    <td>
                                        <MeasurementValue :type="Acceleration" :value="topDecelerationPercentileData.average"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                    <td>
                                        <MeasurementValue :type="Acceleration" :value="topDecelerationPercentileData.maximum"
                                            :format="formatFine" nullString="-" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <MissingDataText v-if="!hasTrendsData" message="Player trends unavailable" />
                <div class="trends-container" v-else>
                    <div style="margin-top: 5vh" class="trends-plots-container">
                        <div class="trends-plots">
                            <canvas id="turns-line"></canvas>
                            <canvas id="distance-line"></canvas>
                        </div>
                        <div class="trends-plots">
                            <canvas id="hsr-line"></canvas>
                            <canvas id="peakSpeed-line"></canvas>
                        </div>

                        <div class="trends-plots">
                            <canvas id="sprintDistance-line"></canvas>
                            <canvas id="accelerationDistance-line"></canvas>
                        </div>

                        <div class="trends-plots">
                            <canvas id="accelerations-line"></canvas>
                            <canvas id="decelerations-line"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Chart} from "chart.js";
import {errorHandler} from "@/components/ErrorHandler";
import {UserData} from "@/components/UserData";
import UpdateSetting from "@/utils/UpdateSetting";
import Colours from "@/utils/Colours";
import { MeasurementTypes, getMeasurementUnit, MeasurementUnit as MU } from "@/utils/MeasurementSystem";
import { getSeasons, Season } from "@/utils/Seasons";
import { getTurnRatesPercentages, getTurnRatesRanges, getTurnRatesLabels } from "@/utils/TurnRateHelpers";
import { getTurnEntrySpeedClassifications } from "@/utils/TurnEntrySpeedBoundaryHelpers";
import configStore from '@/store/config';
import { getSquadDescription, loadSelectedSquadIds } from "@/utils/Squad";
import calculateMaxTurn from "@/utils/CalculateMaxTurn";

export default {
    data() {
        return {
            PeakSpeed: MeasurementTypes.PeakSpeed,
            ShortDistance: MeasurementTypes.ShortDistance,
            LongDistance: MeasurementTypes.LongDistance,
            Speed: MeasurementTypes.Speed,
            Acceleration: MeasurementTypes.Acceleration,
            formatStandard: MU.prototype.formatStandard,
            formatFine: MU.prototype.formatFine,
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed),
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            players: [],
            selectedPlayerIdx: 0,
            indexPlayer: null,
            turn: [],
            distance: [],
            hsr: [],
            sprintDistance: [],
            maxVelocity: [],
            peakSpeed: [],
            mediumHighAccelerations: [],
            mediumHighDecelerations: [],
            accelerationDistance: [],
            leftTurns: [],
            rightTurns: [],
            scatterTurnsLeft: [],
            scatterTurnsRight: [],
            turnData: [],
            playerDataUnavailable: null,
            distanceData: [],
            hsrData: [],
            sprintDistanceData: [],
            maxVelocityData: [],
            peakSpeedData: [],
            mediumHighAccelerationsData: [],
            mediumHighDecelerationsData: [],
            accelerationDistanceData: [],
            isWIP: UserData.isWIP(),
            topAccelerationPercentileData: [],
            topDecelerationPercentileData: [],
            playerId: null,
            speedSelected: [],
            accelerationsSelected: [],
            accelerationsOptions: [
                { text: 'Low', value: 0 },
                { text: 'Medium', value: 1 },
                { text: 'High', value: 2 },
            ],
            decelerationsSelected: [],
            decelerationsOptions: [
                { text: 'Low', value: 0 },
                { text: 'Medium', value: 1 },
                { text: 'High', value: 2 }
            ],
            timesSelected: [],
            timeOptions: [
                { text: '0-0.3s', value: 0 },
                { text: '0.3s-0.5s', value: 1 },
                { text: '0.5s-1s', value: 2 },
            ],
            anglesSelected: [],
            angleOptions: [
                { text: '<60°', value: 0, disabled: false },
                { text: '>60° - <120°', value: 1 },
                { text: '>120°', value: 2, disabled: false },
            ],
            addModal: false,
            range: {
                start: new Date(),
                end: new Date()
            },
            dateRange: null,
            hasSetDateRange: false,
            minSpeed: 1,
            maxSpeed: null,
            minAngle: 0,
            maxAngle: 180,
            seasonSelected: Season.ALL,
            seasonOptions: null,
            getSquadDescription: getSquadDescription,
            customerId: UserData.customerId(),
            selectedTurnClass: 'byTotalTurns',
            turnClassOptions: [
                { text: 'Total Turns', value: 'byTotalTurns' },
                { text: 'Entry Speed', value: 'byEntrySpeed' },
                { text: 'Turn Angle', value: 'byAngleClass' },
                { text: 'Turn Peak Accelerations', value: 'byAccelerationPeakClass' },
                { text: 'Turn Peak Decelerations', value: 'byDecelerationPeakClass' },
            ],
            totalTurnCount: null,
            leftTurnsPercentage: null,
            rightTurnsPercentage: null,
            longitudinalView: false,
            data: null,
            hasKpiSummaryData: null,
            hasTrendsData: null,
            hasTurnData: null,
            speedLabels: null,
            angleLabels: null,
            accelerationsLabels: null,
            decelerationsLabels: null,
            turnRatesData: null,
            plotHeight:null,
            viewport: null,
            useShirtNumber: UserData.useShirtNumber(),
            selectedMatchTime: 'fullMatch',
            matchTimeOptions: [
                { text: 'Full Match', value: 'fullMatch' },
                { text: 'Per Minute', value: 'perMinute' }
            ],
            selectedTurnSide: 'leftRight', 
            turnSideOptions: [
                { text: 'Left & Right', value: 'leftRight' },
                { text: 'Combined', value: 'combined' }
            ],
            hasAllSessionsAsDefault: UserData.hasAllSessionsAsDefault(),
            sessionTypeSelected: null,
            sessionTypeOptions: [],
            turnEntrySpeedBoundaries: UserData.turnEntrySpeedBoundaries, 
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
            console.log(newPage);
        },
        async getPlayers() {
            const response = await this.$root.webApiGet(
                `/players?customerId=${this.customerId}`
            );

            if (response.status === 200) {
                const sortPlayers = response.data.sort(
                    (a, b) =>
                        a.name.localeCompare(b.name)
                );

                const selectedSquads = loadSelectedSquadIds();
                const isActive = sortPlayers.sort((a, b) => b.isActive - a.isActive);
                this.players = isActive.filter(t => selectedSquads.includes(t.squadId));
            } else {

                errorHandler.error(response, this);
            }
        },
        getTurnsByDateRange(data) {
            //check that a date range has been set on the calendar picker
            if (this.range.start.toDateString() === new Date().toDateString()) {
                return data;
            } else {
                return data.filter(session => {
                    const setDateToMidnight = date => new Date(new Date(date).setUTCHours(0, 0, 0, 0));
                    const sessionDate = setDateToMidnight(session.sessionDate);
                    return sessionDate >= setDateToMidnight(this.range.start) && sessionDate <= setDateToMidnight(this.range.end);
                });
            }
        },
        async getPlayer(playerId) {
            let url = `/player?customerId=${this.customerId}&playerId=${playerId}`; 
            if (this.sessionTypeSelected !== 'allSessions') {
                url += `&sessionTypeId=${this.sessionTypeSelected}`;
            }
            const response = await this.$root.webApiGet(url);
            this.playerId = playerId;
            if (response.status === 200) {
                this.data = response.data;
                
                if (this.data.turns.length > 0) {
                    const earliestDate = new Date(Math.min(...this.data.turns.map(session => new Date(session.sessionDate).valueOf())));
                    this.seasonOptions = getSeasons(earliestDate, true);
                } else {
                    this.seasonOptions = [Season.ALL];
                    this.seasonSelected = Season.ALL;
                }
                this.hasTrendsData = this.data.sessionKpis.length > 0;
                this.hasKpiSummaryData = this.data.kpisSummary !== null;
                this.hasTurnData = this.data.turns.length > 0;
                this.playerDataUnavailable = !this.hasTurnData && !this.hasTrendsData;
                this.turnEntrySpeedBoundaries = this.data.turnEntrySpeedBoundaries;
            } else {
                errorHandler.error(response, this);
            }
        },
        getTrendsPlots() {
            if (this.hasTrendsData) {
                const sessionKpis = this.data.sessionKpis;
                const startDate = new Date("2020-11-23");
                const filteredKpis = sessionKpis.filter(s => new Date(s.sessionDate) >= startDate);
                const firstSessionDate = filteredKpis.length === 0 ? startDate : new Date(filteredKpis[0].sessionDate);
                 // Set the starting point of the plot to the last day of the month preceding firstSessionDate
                const xAxisStartDate = new Date(Date.UTC(firstSessionDate.getUTCFullYear(), firstSessionDate.getUTCMonth(), 0));
                const sessionTitles = sessionKpis.map(t => t.title);

                const drawLineChart = (data, title) => {
                    const { kpis, kpisAsSub, sessionDateStrings } = data;
                    return {
                        type: 'line',
                        data: {
                            labels: sessionDateStrings,
                            datasets: [{
                                label: title,
                                data: kpis,
                                fill: false,
                                borderColor: Colours.SPORTLIGHT_TEAL,
                                backgroundColor: Colours.SPORTLIGHT_TEAL,
                                borderWidth: 1,
                            },
                            {
                                label: title,
                                data: kpisAsSub,
                                fill: false,
                                borderColor: Colours.PRIMARY_GREY,
                                backgroundColor: Colours.SPORTLIGHT_TEAL,
                                borderWidth: 0,

                            }]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            spanGaps: true,
                            title: {
                                display: true,
                                text: title
                            },
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        padding: 0,
                                    },
                                    gridLines: {
                                        display: true,
                                        color: Colours.PRIMARY_GREY,
                                        lineWidth: 0,
                                        zeroLineColor: '#fff',
                                        zeroLineWidth: 1,
                                        drawBorder: false,
                                    },
                                }],
                                xAxes: [{
                                    type: 'time',
                                    position: 'bottom',
                                    ticks: {
                                        min: xAxisStartDate
                                    },
                                    time: {
                                        displayFormats: { 'day': 'DD/MM/YY' },
                                        tooltipFormat: 'DD-MM-YYYY',
                                        unit: 'month',
                                    },
                                    gridLines: {
                                        display: true,
                                        color: Colours.PRIMARY_GREY,
                                        lineWidth: 0.2,
                                        zeroLineColor: '#fff',
                                        zeroLineWidth: 1,
                                    },
                                }]
                            },
                            tooltips: {
                                callbacks: {
                                    title: function (data) {
                                        const index = data[0].index;
                                        return [sessionTitles[index], sessionDateStrings[index]];
                                    },
                                },
                            }
                        },
                    };
                };

                const retrieveKpiData = (metric, measurementUnit = null) => {
                    let kpis = filteredKpis.map(d => d.activeTimeM >= 85 ? d[metric] : null);
                    let kpisAsSub = filteredKpis.map(d => d.activeTimeM < 85 ? d[metric] : null);
                    const sessionDateStrings = filteredKpis.map(d => d.sessionDate);
                    if (measurementUnit !== null) {
                        kpis = kpis.map(t => t !== null ? measurementUnit.formatStandard(t) : null);
                        kpisAsSub = kpisAsSub.map(t => t !== null ? measurementUnit.formatStandard(t) : null);
                    }
                    return { kpis, kpisAsSub, sessionDateStrings };
                };

                const renderChart = (chartCtx, chartId, chartConfig) => {
                    if (chartCtx !== undefined) {
                        chartCtx.destroy();
                    }
                    return new Chart(document.getElementById(chartId).getContext("2d"), chartConfig);
                };

                this.distanceCtx = renderChart(this.distanceCtx, 'distance-line',
                    drawLineChart(retrieveKpiData('activeDistanceM', this.ldUnit), `Distance (${this.ldUnit.unitShort})`)
                );
                this.hsrCtx = renderChart(this.hsrCtx, 'hsr-line',
                    drawLineChart(retrieveKpiData('highSpeedDistanceM', this.sdUnit), `High Speed Distance (${this.sdUnit.unitShort})`)
                );
                this.decelerationsCtx = renderChart(this.decelerationsCtx, 'decelerations-line',
                    drawLineChart(retrieveKpiData('mediumHighDecelerations'), 'Medium & High Decelerations')
                );
                this.accelerationsCtx = renderChart(this.accelerationsCtx, 'accelerations-line',
                    drawLineChart(retrieveKpiData('mediumHighAccelerations'), 'Medium & High Accelerations')
                );
                this.sprintDistanceCtx = renderChart(this.sprintDistanceCtx, 'sprintDistance-line',
                    drawLineChart(retrieveKpiData('sprintDistanceM', this.sdUnit), `Sprint Distance (${this.sdUnit.unitShort})`)
                );
                this.accelerationDistanceCtx = renderChart(this.accelerationDistanceCtx, 'accelerationDistance-line',
                    drawLineChart(retrieveKpiData('accelerationDistanceM', this.sdUnit), `Acceleration Distance (${this.sdUnit.unitShort})`)
                );
                this.peakSpeedCtx = renderChart(this.peakSpeedCtx, 'peakSpeed-line',
                    drawLineChart(retrieveKpiData('peakSpeedMPerS', this.speedUnit), `Peak Speed (${this.speedUnit.unitShort})`)
                );
                this.turnsCtx = renderChart(this.turnsCtx, 'turns-line',
                    drawLineChart(retrieveKpiData('turnCount'), 'Turns')
                );
            }
        },
        getKpiSummary() {
            if (this.hasKpiSummaryData) {
                const kpisSummary = this.data.kpisSummary;

                const getKpiData = (metric, metricPerMinute = null, dp = null) => {
                    const kpi = this.selectedMatchTime === 'perMinute' && metricPerMinute ? kpisSummary[metricPerMinute] : kpisSummary[metric];

                    if (kpi) {
                        const { average, maximum } = kpi;
                        return {
                            average: average === null ? null : parseFloat(dp !== null ? average.toFixed(dp) : average),
                            maximum: maximum === null || average === null ? null : parseFloat(dp !== null ? maximum.toFixed(dp) : maximum)
                        };
                    } else {
                        return { average: null, maximum: null };
                    }
                };

                this.turnData = getKpiData('turns', 'turnsPerActiveTime', this.selectedMatchTime === 'perMinute' ? 2 : 1);
                this.distanceData = getKpiData('activeDistanceM', 'activeDistanceMPerActiveTime');
                this.hsrData = getKpiData('highSpeedDistanceM', 'highSpeedDistanceMPerActiveTime');
                this.sprintDistanceData = getKpiData('sprintDistanceM', 'sprintDistanceMPerActiveTime');
                this.peakSpeedData = getKpiData('peakSpeedMPerS');
                this.accelerationDistanceData = getKpiData('accelerationDistanceM', 'accelerationDistanceMPerActiveTime');
                this.mediumHighAccelerationsData = getKpiData('mediumHighAccelerations', 'mediumHighAccelerationsPerActiveTime', 1);
                this.mediumHighDecelerationsData = getKpiData('mediumHighDecelerations', 'mediumHighDecelerationsPerActiveTime', 1);
                this.topAccelerationPercentileData = getKpiData('topAccelerationPercentileMPerS');
                this.topDecelerationPercentileData = getKpiData('topDecelerationPercentileMPerS');
            }
        },
        // Returns a mapping from classification (e.g. 'Low', 'Medium' etc...) to the label (e.g. '0-3m/s', '3-5m/s' ...)
        calculateEntrySpeedLabels() {
            const classificationLabels = {};
            this.speedOptions.forEach(s => classificationLabels[s.name] = s.text);
            return classificationLabels;
        },
        getTurnsSummaryPlot() {
            if (this.hasTurnData) {
                const turnCounts = this.data.turnCounts;
                const { lowAngleCount, mediumAngleCount, highAngleCount, totalTurnCount } = turnCounts;

                const getPercentage = (value, total) => ((value / total) * 100).toFixed(1);

                const lowPercentage = getPercentage(lowAngleCount, totalTurnCount);
                const mediumPercentage = getPercentage(mediumAngleCount, totalTurnCount);
                const highPercentage = getPercentage(highAngleCount, totalTurnCount);
                
                const getCountPercentage = (value, angleCount, percentage) => {
                    return (value / angleCount) * percentage;
                };
                
                const classifications = this.turnEntrySpeedBoundaries.names;
                
                const classificationLabels = this.calculateEntrySpeedLabels();
                
                const speedCounts = {};
                classifications.forEach(c => speedCounts[c] = [
                    getCountPercentage(turnCounts.lowAngleSpeedClassifications[c], lowAngleCount, lowPercentage) ,
                    getCountPercentage(turnCounts.mediumAngleSpeedClassifications[c], mediumAngleCount, mediumPercentage),
                    getCountPercentage(turnCounts.highAngleSpeedClassifications[c], highAngleCount, highPercentage)
                ]);


                const speedPercentages = {};
                classifications.forEach(c => speedPercentages[c] = [
                    getPercentage(turnCounts.lowAngleSpeedClassifications[c], lowAngleCount, lowPercentage) ,
                    getPercentage(turnCounts.mediumAngleSpeedClassifications[c], mediumAngleCount, mediumPercentage),
                    getPercentage(turnCounts.highAngleSpeedClassifications[c], highAngleCount, highPercentage)
                ]);
                                
                if (this.turnsPlot !== undefined) {
                    this.turnsPlot.destroy();
                }
                                
                const datasets = classifications.map(c => {  
                    return {
                        label: classificationLabels[c],
                        backgroundColor: Colours.TURN_CLASSIFICATIONS[c],
                        data: speedCounts[c],
                        percentage: speedPercentages[c],
                        classificationName: c
                    }
                });

                const barChartCtx = document.getElementById('bar-chart').getContext("2d");
                
                this.turnsPlot = new Chart(barChartCtx, {
                    type: "bar",
                    data: {
                        labels: ['0°-60°', '60°-120°', '120°-180°'],
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        title: {
                            display: false,
                        },
                        legend: {
                            display: true,
                            onClick: (e) => e.stopPropagation()
                        },
                        scales: {
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    display: true,
                                    value: [20, 50, 100]
                                },
                                gridLines: {
                                    display: true,
                                    color: "#fff",
                                    lineWidth: 1,
                                    zeroLineColor: '#fff',
                                    zeroLineWidth: 1,
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: '% of all Turns'
                                },
                            }],
                            xAxes: [{
                                stacked: true,
                                gridLines: {
                                    display: false,
                                    color: "#fff",
                                    lineWidth: 1,
                                    zeroLineColor: '#fff',
                                    zeroLineWidth: 1
                                }
                            }]
                        },
                        tooltips: {
                            enabled: true,
                            mode: 'nearest',
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    const barLabel = data.datasets[tooltipItem.datasetIndex].label;
                                    const barXLabel = tooltipItem.xLabel;
                                    const barValue = data.datasets[tooltipItem.datasetIndex];
                                    const speedIndex = barXLabel === '0°-60°' ? 0 : barXLabel === '60°-120°' ? 1 : 2;
                                    return `${barLabel}: ${barValue.percentage[speedIndex]}%`;
                                },
                                afterLabel: function (tooltipItem) {
                                    const barValue = tooltipItem.xLabel;
                                    if (barValue === '0°-60°') {
                                        return 'Percentage: ' + lowPercentage + '%';
                                    } else if (barValue === '60°-120°') {
                                        return 'Percentage: ' + mediumPercentage + '%';
                                    } else {
                                        return 'Percentage: ' + highPercentage + '%';
                                    }
                                }
                            }
                        },
                    }
                });
                this.turnsPlot.update();
            }
        },
        getTurnRates() {
            if (this.hasTurnData) {
                const { sessionTurnClassCounts, turnsZScores } = this.data;
                const seasonTurnClassData = sessionTurnClassCounts.filter(turn => this.seasonSelected.contains(new Date(turn.sessionDate)));
                const sessionTurns = this.longitudinalView ? this.getTurnsByDateRange(seasonTurnClassData) : sessionTurnClassCounts;
                const tooltipTitles = [];
                const sessionDates = [];
                let turns = [];

                for (const key in sessionTurns) {
                    const session = sessionTurns[key];
                    const selectedTurnClass = this.selectedTurnClass === 'byTotalTurns' ?
                        session.turnClassCounts.byAngleClass : session.turnClassCounts[this.selectedTurnClass];
                    turns.push(selectedTurnClass);
                    sessionDates.push(session.sessionDate);
                    tooltipTitles.push(session.title);
                }

                const getSelectedTurnClass = selectedTurnClass => {
                    const turnClasses = {
                        'byTotalTurns': null,
                        'byEntrySpeed': this.speedSelected,
                        'byAngleClass': this.anglesSelected,
                        'byAccelerationPeakClass': this.accelerationsSelected,
                        'byDecelerationPeakClass': this.decelerationsSelected
                    };
                    return turnClasses[selectedTurnClass];
                };

                
                const filterOptions = getSelectedTurnClass(this.selectedTurnClass);
                const classificationNames = (this.selectedTurnClass === 'byEntrySpeed') ? 
                        this.turnEntrySpeedBoundaries.names : 
                        ['Low', 'Medium', 'High'];

                const getFilteredTurnCounts = (filterOptions, turn) => {
                    if (this.longitudinalView && filterOptions !== null && filterOptions.length > 0) {
                        const result = {leftClassificationCounts: {}, rightClassificationCounts: {}, combinedClassificationCounts: {}};
                        classificationNames.forEach(c => {
                            result.leftClassificationCounts[c] = 0;
                            result.rightClassificationCounts[c] = 0;
                            result.combinedClassificationCounts[c] = 0;
                        });
                        
                        filterOptions.forEach(f => {
                            // if the filter is set, then set the value of the result property to the value in the turnClassCount
                            const classificationName = classificationNames[f];
                            result.leftClassificationCounts[classificationName] = turn.leftClassificationCounts[classificationName];
                            result.rightClassificationCounts[classificationName] = turn.rightClassificationCounts[classificationName];
                            result.combinedClassificationCounts[classificationName] = turn.combinedClassificationCounts[classificationName];
                            
                        });
                        return {...turn,...result}; // return a new turn object but with the values overridden by those in the results object
                    } else {
                        return turn;
                    }
                };

                turns = turns.map(t => getFilteredTurnCounts(filterOptions, t));

                let type;
                if (this.selectedTurnSide === 'leftRight') {
                    type = this.selectedTurnClass !== 'byTotalTurns' ? { type: 'DEFAULT' } : { type: 'SPLIT_TOTAL' };
                } else {
                    type = this.selectedTurnClass !== 'byTotalTurns' ? { type: 'GROUPED_TOTAL' } : { type: 'TOTAL' };
                }

                const turnRatesPercentages = getTurnRatesPercentages(turns);
                const turnRatesRanges = getTurnRatesRanges(turns, [turnsZScores], [false]);
                const turnRatesLabels = getTurnRatesLabels(sessionDates, turnRatesRanges.leftTurnNormalRange, true, false);
                const sharedData = { ...turnRatesRanges, ...turnRatesPercentages, labels: turnRatesLabels, ...type, tooltipTitles };

                this.turnRatesData = { turns, classificationNames, colours: Colours.TURN_RATES[this.selectedTurnClass], tooltipTitles, ...sharedData };
                this.plotHeight = '100vh';
                this.viewport = sessionDates.length <= 3 ? sessionDates.length * 24 : sessionDates.length * 8;
                
                let totalLeftCount = 0;
                let totalTotalCount = 0;
                let totalRightCount = 0;
                
                turns.forEach((item) => {
                    totalLeftCount += item.leftCount;
                    totalRightCount += item.rightCount;
                    totalTotalCount += item.totalCount;
                });

                this.leftTurnsPercentage = ((totalLeftCount / totalTotalCount) * 100).toFixed(1);
                this.rightTurnsPercentage = ((totalRightCount / totalTotalCount) * 100).toFixed(1);
                this.totalTurnCount = totalTotalCount;
            }
        },
        getTurnScatters() {
            if (this.hasTurnData) {
                const turnsBySeason = this.data.turns.filter(turn => this.seasonSelected.contains(new Date(turn.sessionDate)));
                const turnData = this.getTurnsByDateRange(turnsBySeason);


                // Filters data based on user selection
                // filterOptions is an array containing the indexes of the selected filter checkboxes
                // filters is an array containing filter functions.
                // e.g. if filterOptions = [0, 2] then the first(0) and third(2) checkboxes for that metric have been ticked,
                // so the filter functions at index 0 and 2 in the filters array will be applied to the turnData
                const filterTurnData = (filterOptions, filters) => {
                    
                    if (!filterOptions || filterOptions.length === 0) {
                        return turnData;
                    }

                    const turnDataFilter = filterOptions
                        .map(i => (i < filters.length) ? filters[i] : () => true) 
                        .reduce( (accumulator, filter) => (t => accumulator(t) || filter(t)), () => false); 
                    
                    return turnData.filter(turnDataFilter);
                };

                //Calculates the min/max values for the x/y axis 
                const getMinMaxPlotValues = (selectedData, valueRanges, defaultMin, defaultMax) => {
                    const result = { min: defaultMin, max: defaultMax };
                    if (selectedData.length > 0) {
                        result.min = Math.min(...selectedData.map(t => valueRanges[t].min));
                        result.max = Math.max(...selectedData.map(t => valueRanges[t].max));
                    }
                    return result;
                };

                const classificationNames = ['Low', 'Medium', 'High'];
                
                const speedLabelText = this.calculateEntrySpeedLabels();
                const anglesLabelText = {'Low': '<60°', 'Medium': '>60° - <120°', 'High': '>120°'};
                const accelerationLabelText = {'Low': 'Low Accelerations', 'Medium': 'Medium Accelerations', 'High': 'High Accelerations'};
                const decelerationsLabelText = {'Low': 'Low Decelerations', 'Medium': 'Medium Decelerations', 'High': 'High Decelerations'};
                
                const getLabels = (filterOptions, labelText, classificationNames) => {
                    return filterOptions.sort().map(f => {
                        return labelText[classificationNames[f]];
                    });
                };
                
                this.speedLabels = getLabels(this.speedSelected, speedLabelText, this.turnEntrySpeedBoundaries.names);
                this.angleLabels = getLabels(this.anglesSelected, anglesLabelText, classificationNames);
                this.accelerationsLabels = getLabels(this.accelerationsSelected, accelerationLabelText, classificationNames);
                this.decelerationsLabels = getLabels(this.decelerationsSelected, decelerationsLabelText, classificationNames);

                const entrySpeedFilters = this.speedOptions.map(o => o.turnEntrySpeedFilter);

                const peakAccelerationFilters = [
                    (t => t.peakAccelerationMPer2 <= 4),
                    (t => t.peakAccelerationMPer2 > 4 && t.peakAccelerationMPer2 < 5.0),
                    (t => t.peakAccelerationMPer2 >= 5.0)
                ];

                const peakDecelerationFilters = [
                    (t => t.peakDecelerationMPer2 >= -4.0),
                    (t => t.peakDecelerationMPer2 < -4.0 && t.peakDecelerationMPer2 > -5.0),
                    (t => t.peakDecelerationMPer2 <= -5.0)
                ];

                const twistingTimeFilters = [
                    (t => t.twistingTimeS < 0.3),
                    (t => t.twistingTimeS >= 0.3 && t.twistingTimeS <= 0.5),
                    (t => t.twistingTimeS > 0.5)
                ];

                const angleDegreeFilters = [
                    (t => t.angleDegrees < 60),
                    (t => t.angleDegrees >= 60 && t.angleDegrees <= 120),
                    (t => t.angleDegrees > 120)
                ];

                const speedData = filterTurnData(this.speedSelected, entrySpeedFilters);
                const accelerationsData = filterTurnData(this.accelerationsSelected, peakAccelerationFilters);
                const decelerationsData = filterTurnData(this.decelerationsSelected, peakDecelerationFilters);
                const turnTimeData = filterTurnData(this.timesSelected, twistingTimeFilters);
                const angleData = filterTurnData(this.anglesSelected, angleDegreeFilters);

                const filteredCommonData = speedData.filter(value =>
                    accelerationsData.includes(value) &&
                    decelerationsData.includes(value) &&
                    turnTimeData.includes(value) &&
                    angleData.includes(value)
                );

                const maxSpeed = calculateMaxTurn(filteredCommonData, 'entrySpeedMPerS');
                const paddedMaxSpeed = maxSpeed >= this.speedUnit.convert(8) ? maxSpeed + this.speedUnit.convert(0.3) : this.speedUnit.convert(8);
                const maxAngle = calculateMaxTurn(filteredCommonData, 'angleDegrees');

                const angleValueRanges = [
                    { min: 0, max: 60 },
                    { min: 60, max: 120 },
                    { min: 120, max: maxAngle }
                ];

                const speedValueRanges = this.speedOptions.map(o => o.speedValueRange);
                speedValueRanges[speedValueRanges.length-1].max = paddedMaxSpeed;

                [this.minAngle, this.maxAngle] = Object.values(getMinMaxPlotValues(this.anglesSelected, angleValueRanges, 0, maxAngle));
                [this.minSpeed, this.maxSpeed] = Object.values(getMinMaxPlotValues(this.speedSelected, speedValueRanges, 1, paddedMaxSpeed));
                const [leftTurns, rightTurns] = ['L', 'R'].map(side => filteredCommonData.filter(t => t.turnSide === side));
                this.scatterTurnsLeft = this.scatterTurns(leftTurns);
                this.scatterTurnsRight = this.scatterTurns(rightTurns);
            }
        },
        turnsScattersAdditionalTooltipText(tooltipItem, data) {
            const indexData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return ['Date: ' + indexData.date, 'Opposition: ' + indexData.title];
        },
        scatterTurns(turns) {
            return turns.map(t => {
                return {
                    time: t.twistingTimeS,
                    x: this.speedUnit.formatFine(t.entrySpeedMPerS),
                    y: t.angleDegrees,
                    date: t.sessionDate,
                    title: t.title
                };
            });
        },
        async selectPlayer(p) {
            this.$root.executeTaskWithProgressBar(async () => {
                this.selectedPlayerIdx = p;
                await this.refreshData();
            });
        },
        async refreshData() {
            this.$root.executeTaskWithProgressBar(async () => {
                this.resetToDefault();
                const playerId = this.players[this.selectedPlayerIdx].playerId;
                await this.getPlayer(playerId);
                this.getKpiSummary();
                this.getTrendsPlots();
                this.getTurnsSummaryPlot();
                this.getTurnRates();
                this.getTurnScatters();
            });
        },
        async filter() {
            this.addModal = true;
        },
        async clearFilter() {
            this.resetToDefault();
            this.getTurnScatters();
        },
        updateTurnClass() {
            this.getTurnRates();
        },
        lookupSeason(seasonText) {
            return this.seasonOptions.find(season => season.text === seasonText);
        },
        async applyFilter() {
            this.getTurnScatters();
            this.hasSetDateRange = this.range.start.toDateString() !== new Date().toDateString();
            this.dateRange = this.range.start.toISOString().slice(0,10) + "-" + this.range.end.toISOString().slice(0,10);
            if (this.longitudinalView) { this.getTurnRates(); }
        },
        resetToDefault() {
            this.range.start = new Date();
            this.range.end = new Date();
            this.addModal = false;
            this.speedSelected = [];
            this.dateRange = null;
            this.hasSetDateRange = false;
            this.decelerationsSelected = [];
            this.accelerationsSelected = [];
            this.anglesSelected = [];
            this.timesSelected = [];
            this.minSpeed = 1;
            this.maxSpeed = null;
            this.minAngle = 0;
            this.maxAngle = 180;
            this.seasonSelected = Season.ALL;
            this.longitudinalView = false;
            this.getTurnScatters();
            this.getTurnRates();
        },
        disableAngle() {
            this.angleOptions[2].disabled = this.anglesSelected.includes(0);
            this.angleOptions[0].disabled = this.anglesSelected.includes(2);
        },
        async getSessionTypes() {
            const sessionTypes = configStore.state.sessionTypes;
            if (this.hasAllSessionsAsDefault) {
                this.sessionTypeSelected = 'allSessions';
            } else {
                for (const key in sessionTypes) {
                    const sessionType = sessionTypes[key];
                    if (sessionType.isOptionForPlayerScreenSelector) {
                        if (sessionType.isDefaultOption) {
                            this.sessionTypeSelected = sessionType.id;
                        }
                        this.sessionTypeOptions.push({ text: sessionType.name, value: sessionType.id });
                    }
                }
            }
            this.sessionTypeOptions.push({ text: 'Combined', value: 'allSessions' });
        }
    },
    computed: {
        UserData() {
            return UserData;
        },
        disableFilterButton() {
            return (this.seasonSelected === Season.ALL && this.speedSelected.length === 0 && this.accelerationsSelected.length === 0
                && this.decelerationsSelected.length === 0 && this.anglesSelected.length === 0 && 
                this.timesSelected.length === 0 && this.range.start.toDateString() === new Date().toDateString() && 
                    this.range.start.toDateString() === new Date().toDateString());
        },
        speedOptions() {

            const turnEntrySpeedClassifications = getTurnEntrySpeedClassifications(this.turnEntrySpeedBoundaries, this.speedUnit);
            
            for (let i = 0; i < turnEntrySpeedClassifications.length; i++) {
                turnEntrySpeedClassifications[i].value = i;
            }
            
            return turnEntrySpeedClassifications;
        },
    },

    async mounted() {
        this.$root.executeTaskWithProgressBar(async () => {
            console.log("Player.vue mounted");
            this.$root.newPageView("Player Page", UserData.userName());
            await this.getPlayers();
            await this.getSessionTypes();
            if (this.players.length > 0) {
                // load the page with the first enabled player
                const selectedPlayer = this.players.filter(p => UserData.hasPlayerModule(p.playerId))[0];
                if (selectedPlayer) {
                    this.selectedPlayerIdx = this.players.indexOf(selectedPlayer);
                    await this.getPlayer(selectedPlayer.playerId);
                    this.getKpiSummary();
                    this.getTrendsPlots();
                    this.getTurnsSummaryPlot();
                    this.getTurnRates();
                    this.getTurnScatters();
                } else {
                    this.selectedPlayerIdx = -1;
                }
            }
            UpdateSetting();
        });
    }
};
</script>
<style scoped>
#player-dropdown /deep/ .dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
}
.display-labels {
    display: flex; 
    flex-wrap: wrap; 
    margin-top: 0.7vh;
    align-content: space-around;
}
</style>