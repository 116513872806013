<template>
    <div id="content-wrap" style="margin-left:90px;" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }">
        <MissingDataText v-if="!players" message="Selected squad is empty" />
        <div v-else>
            <Header :updateSelection="refreshData" />
            <ActiveFilter />
            <div class="d-flex justify-content-evenly mx-auto mt-0" style="width: 90vw;">
                <LongitudinalKpiAnalysis :sessions="sessions" :data="kpiData" />
                <LongitudinalSprintCount :sessions="sessions" :data="sprintCounts" />
            </div>
            <LongitudinalPeakAnalysis :sessions="sessions" :peakEpochCountData="peakThresholdCounts"
                :peakEpochData="rainbowData" />
            <LongitudinalForceVelocity :sessions="sessions" :data="fvData" />
        </div>
    </div>
</template>

<script>
import Header from './Header';
import store from './store';
import filterStore from './filterStore';
import LongitudinalSprintCount from './LongitudinalSprintCount';
import LongitudinalPeakAnalysis from './LongitudinalPeakAnalysis';
import LongitudinalKpiAnalysis from './LongitudinalKpiAnalysis';
import LongitudinalForceVelocity from './LongitudinalForceVelocity';
import MissingDataText from "@/components/MissingDataText.vue";
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import UpdateSetting from "@/utils/UpdateSetting";
import KpiOption from "@/utils/KpiOption";
import ActiveFilter from "./ActiveFilter.vue";

export default {
    components: {
        MissingDataText,
        Header,
        LongitudinalKpiAnalysis,
        LongitudinalPeakAnalysis,
        LongitudinalSprintCount,
        LongitudinalForceVelocity,
        ActiveFilter
    },
    data() {
        return {
            customerId: UserData.customerId(),
            kpiSelected: KpiOption.defaultSelection,
            kpiOptions: KpiOption.options,
            sessions: null,
            sprintCounts: null,
            peakThresholdCounts: null,
            rainbowData: null,
            kpiData: null,
            earliestSession: null,
            fvData: null,
            playerData: null,
            leagueComparisonData: null,
            positionComparisonData: null,
            activeComparisonData: null
        };
    },
    methods: {
        async refreshData() {
            this.$root.executeTaskWithProgressBar(async () => {
                await filterStore.dispatch('initialiseFilters', { teamType: UserData.teamType(), position: this.selectedPlayer.position });
                if (!filterStore.state.periods) {
                    await filterStore.dispatch('updatePeriods', this);
                }
                await this.getData();
                this.activeComparisonData = this.leagueComparisonData;
                this.getKpiData();
                this.getPeakData();
                this.getSprintCountsData();
                this.getFVData();
            });
        },
        async getData() {
            const startDate = this.selectedPeriod.startDate.toISOString().substring(0, 10);
            const endDate = this.selectedPeriod.endDate.toISOString().substring(0, 10);
            const response = await this.$root.webApiGet(
                `/longitudinalplayerdevelopment?customerId=${this.customerId}` +
                `&playerId=${this.playerId}` +
                `&matchCount=${this.selectedMatchCount}` +
                `&referenceCriteriaId=${this.selectedReferenceGroup.value}` +
                `&playerCriteriaId=${this.selectedPlayerCriteria.value}` +
                `&startDate=${startDate}` +
                `&endDate=${endDate}`);
            if (response.status === 200) {
                const data = response.data;
                this.sessions = data.sessions;
                this.playerData = data.playerData;
                this.leagueComparisonData = data.leagueComparisonData;
                this.positionComparisonData = data.positionComparisonData;
            } else {
                errorHandler.error(response, this);
            }
        },
        getKpiData() {
            const { DISTANCE: distance, HSR: hsr, SPRINT: sprint, TURNS: turns } = this.playerData.kpiDataBySessionId;
            this.kpiData = {
                DISTANCE: this.mapKpiCategory(distance, this.activeComparisonData.kpis.distance),
                HSR: this.mapKpiCategory(hsr, this.activeComparisonData.kpis.hsd),
                SPRINT: this.mapKpiCategory(sprint, this.activeComparisonData.kpis.sprintDistance),
                TURNS: this.mapKpiCategory(turns, this.activeComparisonData.kpis.turns),
            };
        },

        getPeakData() {
            const generateKpiEpochData = (kpiData, comparisonData) => {
                const { sessionValues, rollingAverages } = this.retrievePlayerSessionData(kpiData);
                if (sessionValues.length > 0 && sessionValues.some(t => t !== null)) {
                    return {
                        sessionValues: sessionValues,
                        rollingAverage: rollingAverages,
                        epochValues: comparisonData ? comparisonData : []
                    };
                }
            };

            const generateRainbowData = kpiKey => {
                const playerPeakEpochs = this.playerData.peakPhysicalCapacityBySessionId[kpiKey];
                if (playerPeakEpochs) {
                    const comparisonEpoch = this.activeComparisonData.peakPhysicalCapacityRainbowData[kpiKey];
                    return {
                        _30S: generateKpiEpochData(playerPeakEpochs._30S, comparisonEpoch ? comparisonEpoch._30S : null),
                        _60S: generateKpiEpochData(playerPeakEpochs._60S, comparisonEpoch ? comparisonEpoch._60S : null),
                        _120S: generateKpiEpochData(playerPeakEpochs._120S, comparisonEpoch ? comparisonEpoch._120S : null)
                    };
                }
            };

            this.rainbowData = {
                DISTANCE: generateRainbowData('DISTANCE'),
                HSR: generateRainbowData('HSR'),
                SPRINT: generateRainbowData('SPRINT'),
                TURNS: generateRainbowData('TURNS')
            };

            const generatePeakThresholdCounts = kpiKey => {
                const playerThresholds = this.playerData.peakThresholdCountBySessionId[kpiKey];
                if (playerThresholds) {
                    const comparisonCounts = this.activeComparisonData.peakThresholdCounts[kpiKey];
                    return {
                        _30S: this.mapKpiCategory(playerThresholds._30S, comparisonCounts ? comparisonCounts._30S : null),
                        _60S: this.mapKpiCategory(playerThresholds._60S, comparisonCounts ? comparisonCounts._60S : null),
                        _120S: this.mapKpiCategory(playerThresholds._120S, comparisonCounts ? comparisonCounts._120S : null)
                    };
                }
            };

            this.peakThresholdCounts = {
                DISTANCE: generatePeakThresholdCounts('DISTANCE'),
                HSR: generatePeakThresholdCounts('HSR'),
                SPRINT: generatePeakThresholdCounts('SPRINT'),
                TURNS: generatePeakThresholdCounts('TURNS')
            };
        },

        getSprintCountsData() {
            const generateSprintCounts = (playerCountsBySessionId, thresholdKey, kpiKey) => {
                const playerCounts = playerCountsBySessionId[thresholdKey];
                const comparisonCounts = this.activeComparisonData.sprintCounts[kpiKey];
                return this.mapKpiCategory(playerCounts, comparisonCounts ? comparisonCounts[thresholdKey] : null);
            };

            const { hsrCountsBySessionId, sprintCountsBySessionId } = this.playerData;
            this.sprintCounts = {
                SPRINT: {
                    TOTAL: generateSprintCounts(sprintCountsBySessionId, 'TOTAL', 'SPRINT'),
                    ABOVE_30: generateSprintCounts(sprintCountsBySessionId, 'ABOVE_30', 'SPRINT'),
                    _0_TO_10: generateSprintCounts(sprintCountsBySessionId, '_0_TO_10', 'SPRINT'),
                    _10_TO_20: generateSprintCounts(sprintCountsBySessionId, '_10_TO_20', 'SPRINT'),
                    _20_TO_30: generateSprintCounts(sprintCountsBySessionId, '_20_TO_30', 'SPRINT')
                },
                HSR: {
                    TOTAL: generateSprintCounts(hsrCountsBySessionId, 'TOTAL', 'HSR'),
                    ABOVE_30: generateSprintCounts(hsrCountsBySessionId, 'ABOVE_30', 'HSR'),
                    _0_TO_10: generateSprintCounts(hsrCountsBySessionId, '_0_TO_10', 'HSR'),
                    _10_TO_20: generateSprintCounts(hsrCountsBySessionId, '_10_TO_20', 'HSR'),
                    _20_TO_30: generateSprintCounts(hsrCountsBySessionId, '_20_TO_30', 'HSR')
                },
            };
        },

        getFVData() {
            this.fvData = {
                force: this.mapKpiCategory(this.playerData.forcesBySessionId, this.activeComparisonData.force),
                velocity: this.mapKpiCategory(this.playerData.velocitiesBySessionId, this.activeComparisonData.velocity)
            };
        },
        retrievePlayerSessionData(kpiData) {
            const sessionValues = [];
            const rollingAverages = [];

            this.sessions.forEach(session => {
                const sessionData = kpiData ? kpiData[session.sessionId] : null;
                if (sessionData) {
                    sessionValues.push(sessionData.sessionValue);
                    rollingAverages.push(sessionData.rollingAverage);
                } else {
                    sessionValues.push(null);
                    rollingAverages.push(null);
                }
            });
            return { sessionValues, rollingAverages };

        },
        mapKpiCategory(kpiData, comparisonData) {
            const { sessionValues, rollingAverages } = this.retrievePlayerSessionData(kpiData);
            if (sessionValues.length > 0 && sessionValues.some(t => t !== null)) {
                return {
                    sessionValues: sessionValues,
                    rollingAverage: rollingAverages,
                    average: comparisonData ? [comparisonData.average] : [],
                    lowerBound: comparisonData ? [comparisonData.average - comparisonData.standardDeviation]: [],
                    upperBound: comparisonData ? [comparisonData.average + comparisonData.standardDeviation] : []
                };
            }
        }

    },
    async mounted() {
        UpdateSetting();
        this.$root.executeTaskWithProgressBar(async () => {
            await store.dispatch('getPlayers', this);
            await this.refreshData();
            this.$root.newPageView("Longitudinal Player Development Page", UserData.userName());
        });
        console.log("LongitudinalAnalysis.vue mounted");
    },
    computed: {
        UserData() {
            return UserData;
        },
        players() {
            return store.state.players;
        },
        selectedPlayerIdx() {
            return store.state.selectedPlayerIdx;
        },
        selectedPlayer() {
            return store.state.selectedPlayer;
        },
        playerId() {
            return store.state.selectedPlayer.playerId;
        },
        selectedKpiTitle() {
            return this.kpiOptions.find(t => t.value === this.kpiSelected).text;
        },
        selectedPlayerCriteria() {
            return filterStore.state.selectedPlayerCriteria;
        },
        selectedReferenceGroup() {
            return filterStore.state.selectedReferenceGroup;
        },
        selectedPositionAverage() {
            return filterStore.state.selectedPositionAverage;
        },
        selectedPeriod() {
            return filterStore.state.selectedPeriod;
        },
        selectedMatchCount() {
            return filterStore.state.selectedMatchCount;
        },
        combinedSelection() {
            //ensure refreshData gets called once when there's a change to the filter selection
            return `${this.selectedPlayerCriteria.value}-${this.selectedReferenceGroup.value}-${this.selectedPeriod?.value}-${this.selectedMatchCount}`;
        },
    },
    watch: {
        combinedSelection() {
            this.refreshData();
        },
        selectedPositionAverage() {
            this.activeComparisonData = this.selectedPositionAverage === "avg" ? this.leagueComparisonData : this.positionComparisonData;
            this.getKpiData();
            this.getPeakData();
            this.getSprintCountsData();
            this.getFVData();
        }
    },
};
</script>
<style>
.longitudinal-analysis-list li {
    margin-bottom: 10px;
}
</style>