<template>
    <div class="section-container w-50 mt-0">
        <SectionTitle title="Sprint Counts Over Time">
            <MoreInfo :id="'longitudinal-sprint-more-info'" title='Sprint Counts Over Time'>
                <ul class="longitudinal-analysis-list">
                    <li> Sprint Counts Over Time displays each player's number of sprints longitudinally compared to the
                        {{ selectedReferenceGroupText }} data.</li>
                    <SharedInfoText />
                </ul>
            </MoreInfo>
        </SectionTitle>
        <div class="d-flex justify-content-center mx-auto">
            <RadioButton class="mx-4" :id="'speed-type-radio-group'" :modal="speedTypeSelected"
                :options="speedTypeOptions" :name="'speed-type-options'" @update-modal="speedTypeSelected = $event"
                :task="updateSprintCounts" />
            <DropdownSelector @item-selected="selectSprint" :items="sprintThresholdOptions"
                :getItemName="sprintSelectorDisplayText" :index="sprintIdx" />
        </div>
        <MissingDataText v-if="!hasData" message="Insufficient data for player" />
        <div v-else>
            <ChartLegend />
            <LongitudinalChart :id="speedTypeSelected + '-' + sprintIdx + '-counts'"
                :title="speedTypeOptions[speedTypeSelected].text" :data="sprintData" />
        </div>
    </div>
</template>

<script>
import { convertChart } from './ChartDataConverter';
import LongitudinalChart from './LongitudinalChart';
import MissingDataText from "@/components/MissingDataText.vue";
import RadioButton from "@/components/RadioButton.vue";
import DropdownSelector from "@/components/DropdownSelector";
import SectionTitle from "@/components/SectionTitle.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";
import SprintThresholdCountOptions from "@/utils/SprintThresholdCountOptions.js";
import ChartLegend from "./ChartLegend";
import SharedInfoText from "./SharedInfoText";
import filterStore from './filterStore';
const SPEED_TYPE = { SPRINT: 0, HSR: 1 };

export default {
    props: {
        sessions: Array,
        data: Object,
    },
    components: {
        MissingDataText,
        RadioButton,
        SectionTitle,
        DropdownSelector,
        LongitudinalChart,
        MoreInfo,
        ChartLegend,
        SharedInfoText
    },
    data() {
        return {
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            countUnit: getMeasurementUnit(MeasurementTypes.Count),
            sprintData: null,
            sprintIdx: 0,
            speedTypeSelected: 0,
            speedTypeOptions: [
                { text: 'Sprint', value: SPEED_TYPE.SPRINT },
                { text: 'HSR', value: SPEED_TYPE.HSR }
            ],
            hasData: false,
        };
    },
    methods: {
        updateSprintCounts() {
            if (this.data) {
                const selectedType = this.speedTypeSelected === SPEED_TYPE.HSR ? 'HSR' : 'SPRINT';
                const selectedThresholdValue = this.sprintThresholdOptions[this.sprintIdx].value;
                const selectedData = this.data[selectedType][selectedThresholdValue];
                this.hasData = selectedData !== undefined;
                if (this.hasData) {
                    this.sprintData = convertChart(selectedData, this.sessions, this.countUnit);
                }
            }
        },
        sprintSelectorDisplayText(sprint) {
            return sprint.text;
        },
        selectSprint(sprint) {
            this.sprintIdx = sprint;
            this.updateSprintCounts();
        }
    },
    computed: {
        sprintThresholdOptions() {
            return SprintThresholdCountOptions;
        },
        selectedReferenceGroupText() {
            return filterStore.state.selectedReferenceGroup.text;
        },
    },
    watch: {
        speedTypeSelected() {
            this.updateSprintCounts();
        },
        sprintIdx() {
            this.updateSprintCounts();
        },
        data() {
            this.updateSprintCounts();
        },
    },
    async mounted() {
        this.updateSprintCounts();
    },
};
</script>