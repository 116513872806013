<template>
    <div class="section-container">
        <SectionTitle title="Longitudinal Force Velocity">
            <MoreInfo :id="'longitudinal-f0v0-more-info'" title='Longitudinal Force Velocity'>
                <ul class="longitudinal-analysis-list">
                    <li class="font-weight-bold"> F0 Over Time </li>
                    <li> Longitudinal F0 displays the selected player's F0 from eligible matches.</li>
                </ul>
                <ul class="longitudinal-analysis-list">
                    <li class="font-weight-bold">V0 Over Time</li>
                    <li> Longitudinal V0 displays the selected player's V0 from eligible matches.</li>
                </ul>
            </MoreInfo>
        </SectionTitle>
        <div class="d-flex justify-content-between w-100 mx-auto">
            <div class="w-50 mx-auto">
                <MissingDataText v-if="!hasData" message="Insufficient data for player" />
                <div v-else>
                    <ChartLegend class="my-4" />
                    <LongitudinalChart id="force-chart'" :data="forceData" title="F0 Over Time" />
                </div>
            </div>
            <div class="w-50 mx-auto">
                <MissingDataText v-if="!hasData" message="Insufficient data for player" />
                <div v-else>
                    <ChartLegend class="my-4" />
                    <LongitudinalChart id="velocity-chart'" :data="velocityData" title="V0 Over Time" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { convertChart } from './ChartDataConverter';
import LongitudinalChart from './LongitudinalChart';
import MissingDataText from "@/components/MissingDataText.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import ChartLegend from "./ChartLegend";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";

export default {
    props: {
        sessions: Array,
        data: Object,
        period: String,
    },
    components: {
        MissingDataText,
        SectionTitle,
        LongitudinalChart,
        MoreInfo,
        ChartLegend
    },
    data() {
        return {
            forceData: null,
            velocityData: null,
            countUnit: getMeasurementUnit(MeasurementTypes.Count),
            hasData: false
        };
    },
    methods: {
        updateFVData() {
            if (this.data) {
                this.hasData = this.data.force !== undefined;
                if (this.hasData) {
                    const force = convertChart(this.data.force, this.sessions, this.countUnit);
                    const velocity = convertChart(this.data.velocity, this.sessions, this.countUnit);
                    force.yAxisLabel = "";
                    velocity.yAxisLabel = "";
                    this.forceData = force;
                    this.velocityData = velocity;
                }
            }
        }
    },
    watch: {
        data() {
            this.updateFVData();
        },
    },
    async mounted() {
        this.updateFVData();
    },
};
</script>