<template>
    <div class="section-container">
        <SectionTitle title="Longitudinal Peak Analysis">
            <MoreInfo :id="'longitudinal-peak-demands-more-info'" title='Longitudinal Peak Analysis'>
                <h4 class="font-weight-bold">Peak Physical Capacity Capacity Over Time </h4>
                <ul class="longitudinal-analysis-list">
                    <li> Peak Physical Capacity Over Time displays each player's average peak periods longitudinally
                        compared to the {{ selectedReferenceGroupText }} data.</li>
                    <SharedInfoText />
                </ul>
                <h4 class="font-weight-bold">Peak Threshold Count Over Time</h4>
                <ul class="longitudinal-analysis-list">
                    <li>Peak Threshold Count Over Time displays each player's average peak periods longitudinally
                        compared to the {{ selectedReferenceGroupText }} data.</li>
                    <SharedInfoText />
                </ul>

            </MoreInfo>
        </SectionTitle>
        <div class="d-flex justify-content-center my-4 mx-auto">
            <RadioButton class="mx-4" :id="'kpi-radio-group'" :modal="kpiSelected" :options="kpiOptions"
                :name="'kpi-epoch-radio-options'" @update-modal="updateKpi" :task="updatePeakCharts" />
            <RadioButton :id="'epoch-radio-group'" :modal="epochSelected" :options="epochOptions"
                :name="'epoch-radio-options'" @update-modal="epochSelected = $event" :task="updatePeakCharts" />
        </div>
        <div class="d-flex justify-content-between w-100 mx-auto">
            <div class="w-50 mx-auto">
                <MissingDataText v-if="!hasPeakEpochData" message="Insufficient data for player" />
                <div v-else>
                    <h4 class="w-100 text-center">Peak Physical Capacity Over Time</h4>
                    <div class="d-flex align-items-center mx-auto my-4" style="height: 1vh; width: 40vw;">
                        <ChartLegend class="w-25" :displayComparisonKeys="false" />
                        <RainbowLegend class="w-75" style="margin-top:5vh" :showBlueKey="false"
                            :keyText="referenceGroupText" />
                    </div>
                    <LongitudinalChart :id="epochSelected + '-peak-chart-capacity-id'"
                        :title="selectedKpiTitle + ' ' + title" :data="rainbowData" />
                </div>
            </div>
            <div class="w-50 mx-auto">
                <MissingDataText v-if="!hasPeakEpochCountData" message="Insufficient data for player" />
                <div v-else>
                    <h4 class="w-100 text-center">Peak Threshold Count Over Time</h4>
                    <div class="d-flex align-items-center mx-auto my-4" style="height: 1vh; width: 40vw;">
                        <ChartLegend />
                    </div>
                    <LongitudinalChart :id="epochSelected + '-peak-count-capacity-id'"
                        :title="selectedKpiTitle + ' ' + title" :data="countsData" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { convertChart, convertRainbowChart } from './ChartDataConverter';
import LongitudinalChart from './LongitudinalChart';
import filterStore from './filterStore';
import store from './store';
import MissingDataText from "@/components/MissingDataText.vue";
import RadioButton from "@/components/RadioButton.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import RainbowLegend from "@/components/RainbowLegend.vue";
import ChartLegend from "./ChartLegend.vue";
import Colours from "@/utils/Colours";
import SharedInfoText from "./SharedInfoText";
import KpiOption from "@/utils/KpiOption";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";

export default {
    props: {
        sessions: Array,
        peakEpochCountData: Object,
        peakEpochData: Object,
    },
    components: {
        MissingDataText,
        RadioButton,
        SectionTitle,
        LongitudinalChart,
        MoreInfo,
        RainbowLegend,
        ChartLegend,
        SharedInfoText
    },
    data() {
        return {
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            countUnit: getMeasurementUnit(MeasurementTypes.Count),
            rainbowData: null,
            countsData: null,
            epochSelected: '_30S',
            epochOptions: [
                { text: '30s', value: '_30S' },
                { text: '60s', value: '_60S' },
                { text: '120s', value: '_120S' },
            ],
            title: '',
            Colours,
            hasPeakEpochData: false,
            hasPeakEpochCountData: false,
            kpiSelected: KpiOption.defaultSelection
        };
    },
    methods: {
        updatePeakCharts() {
            if (this.peakEpochData) {
                this.hasPeakEpochData = this.peakEpochData[this.kpiSelected] !== undefined;
                    if (this.hasPeakEpochData) {
                        const unit = this.kpiSelected === 'TURNS' ? this.countUnit : this.sdUnit;
                        const selectedKpi = this.peakEpochData[this.kpiSelected];
                        const convertedPeakData = {
                            '_30S': convertRainbowChart(selectedKpi._30S, this.sessions, unit),
                            '_60S': convertRainbowChart(selectedKpi._60S, this.sessions, unit),
                            '_120S': convertRainbowChart(selectedKpi._120S, this.sessions, unit)
                        };
                        this.rainbowData = convertedPeakData[this.epochSelected];
                    }
            }
            if (this.peakEpochCountData) {
                this.hasPeakEpochCountData = this.peakEpochCountData[this.kpiSelected] !== undefined &&
                    this.peakEpochCountData[this.kpiSelected][this.epochSelected] !== undefined;
                if (this.hasPeakEpochCountData) {
                    const selectedKpi = this.peakEpochCountData[this.kpiSelected];
                    const convertedPeakCountData = {
                        '_30S': convertChart(selectedKpi._30S, this.sessions, this.countUnit),
                        '_60S': convertChart(selectedKpi._60S, this.sessions, this.countUnit),
                        '_120S': convertChart(selectedKpi._120S, this.sessions, this.countUnit)
                    };
                    this.countsData = convertedPeakCountData[this.epochSelected];
                }
            }
            this.title = this.epochOptions.find(t => t.value === this.epochSelected).text;
        },
        updateKpi(event) {
            this.kpiSelected = event;
        }
    },
    computed: {
        selectedKpiTitle() {
            return this.kpiOptions.find(t => t.value === this.kpiSelected).text;
        },
        kpiOptions() {
            return filterStore.state.kpiOptions;
        },
        selectedPlayerPosition() {
            return store.state.selectedPlayer.position;
        },
        selectedPositionAverage() {
            return filterStore.state.selectedPositionAverage;
        },
        selectedReferenceGroupText() {
            return filterStore.state.selectedReferenceGroup.text;
        },
        referenceGroupText() {
            return `${this.selectedReferenceGroupText} ${this.selectedPositionAverage === "posAvg" ? this.selectedPlayerPosition : ''}`;
        }
    },
    watch: {
        peakEpochData() {
            this.updatePeakCharts();
        },
        peakEpochCountData() {
            this.updatePeakCharts();
        },
    },
    async mounted() {
        this.updatePeakCharts();
    },
};
</script>
