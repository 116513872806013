<template>
    <b-nav id="top-nav">
        <b-button v-b-modal.settings-edit-modal id="settings-button" style="background:none; border:none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-settings">
                <circle cx="12" cy="12" r="3"></circle>
                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65
                    1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0
                    0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0
                    0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83
                    0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51
                    1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0
                    0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                </path>
            </svg>
        </b-button>
        <b-button v-b-modal.customer-support-modal id="customer-support-button"
            style="background:none; border:none">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-help-circle">
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
        </b-button>
        <EditModal :id="'settings-edit-modal'" :ok="storeSetting" :cancel="refreshData"
            :cancelVariant="'secondary standard-btn'" :title="'Update Settings'">
            <RadioButton :modal="measurementSystemSelected" :options="measurementSystemOptions"
                :label="'Select measurement system'" @update-modal="measurementSystemSelected = $event" />

            <RadioButton v-if="measurementSystemSelected === METRIC_ID" :id="'peak-speed-radio-group'"
                :modal="peakSpeedUnitSelected" :options="peakSpeedUnitOptions" :label="'Select peak speed unit'"
                :name="'peak-speed-radio-option'" @update-modal="peakSpeedUnitSelected = $event" />

            <b-form-group label="Select squad(s)" v-slot="{ ariaDescribedby1 }">
                <b-form-checkbox-group id="squad-checkbox" v-model="squadsSelected" :options="squadOptions"
                    :aria-describedby="ariaDescribedby1" name="squad" plain></b-form-checkbox-group>
            </b-form-group>
        </EditModal>
        <b-nav-item v-if="currentPath !== '/login' && !this.$root.userData" @click="navigateTo('/login')">Sign In
        </b-nav-item>

        <EditModal :id="'privacy-settings-edit-modal'" :title="'Privacy Settings'" :ok="cookieConsentAccept"
            :cancel="cookieConsentReject" :cancelTitle="'Reject'" :okTitle="'Accept'"
            :cancelVariant="'secondary standard-btn'">
            <label>
                SPORTLIGHT uses necessary cookies to make our site function and additional cookies to analyze site
                usage, which we use to improve the performance of our website and provide a better user experience.
                By clicking 'Accept', you consent to the use of these additional cookies.
            </label>
        </EditModal>
        <ChangePasswordModal id="change-password-modal" :persistOnClickBackdrop=false />

        <EditModal :id="'customer-support-modal'" :title="'Customer Support'" size="xl" :cancelOnly=true
            cancelVariant="primary standard-btn" cancelTitle="Close" :ok="()=>{}">
            <CustomerSupport />
        </EditModal>

        <b-nav-item-dropdown id="team-dropdown" :html="dropdownHtml" toggle-class="nav-link-custom" right
            v-if="this.$root.userData">
            <b-dropdown-item v-if="this.$root.userData.additionalCustomer"
                @click="switchAccount()">{{ this.$root.userData.additionalCustomer.customerDisplayName }}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-b-modal.privacy-settings-edit-modal>Privacy Settings</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <template v-if="passwordChangeOptionEnabled">
                <b-dropdown-item v-b-modal.change-password-modal>Change Password</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
            </template>
            <b-dropdown-item @click="logOut()">Sign Out</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
        </b-nav-item-dropdown>


    </b-nav>
</template>
<script>
import { UserData } from './UserData';
import { getMeasurementSystemId, setMeasurementSystemId, METRIC_ID, IMPERIAL_ID } from '../utils/MeasurementSystem';
import EditModal from './EditModal.vue';
import ChangePasswordModal from './ChangePasswordModal.vue';
import RadioButton from './RadioButton.vue';
import { SQUADS, storeSelectedSquadIds, loadSelectedSquadIds } from "../utils/Squad";
import AuthHelper from "@/utils/MsalAuthHelper";
import CustomerSupport from "@/components/CustomerSupport";

export default {
    components: {
        EditModal,
        RadioButton,
        ChangePasswordModal,
        CustomerSupport
    },
    data() {
        return {
            measurementSystemSelected: getMeasurementSystemId(),
            measurementSystemOptions: [
                { text: 'metric', value: METRIC_ID },
                { text: 'imperial', value: IMPERIAL_ID }
            ],
            METRIC_ID: METRIC_ID,
            peakSpeedUnitSelected: localStorage.getItem('peakSpeedUnit'),
            peakSpeedUnitOptions: [
                { text: 'm/s', value: 1 },
                { text: 'km/h', value: 3.6 }
            ],
            squadsSelected: loadSelectedSquadIds(),
            squadOptions: SQUADS,
            passwordChangeOptionEnabled: UserData.passwordChangeOptionEnabled()
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            this.measurementSystemSelected = getMeasurementSystemId();
            this.peakSpeedUnitSelected = localStorage.getItem('peakSpeedUnit');
            this.squadsSelected = loadSelectedSquadIds();
        },
        switchAccount() {
            const userData = this.$root.userData;
            //Update user data with new customer data
            this.$root.updateGlobal("userData", {
                token: userData.token,
                primaryCustomer: userData.additionalCustomer,
                additionalCustomer: userData.primaryCustomer,
            });
            //Redirects user to calendar screen
            window.location.href = ('/calendar');

        },
        logOut() {
            AuthHelper.clearLocal();
            this.$root.newEvent("New Logout", UserData.userName());
            this.$root.logOut(1);
        },
        sanitize(string) {
            // used to sanitize the supplied string against XSS attacks
            const map = {
                "&": "&amp;",
                "<": "&lt;",
                ">": "&gt;",
                '"': "&quot;",
                "'": "&#x27;",
                "/": "&#x2F;",
                "`": "&grave;",
            };
            const reg = /[&<>"'/]/gi;
            return string.replace(reg, (match) => map[match]);
        },
        storeSetting() {
            setMeasurementSystemId(this.measurementSystemSelected);
            localStorage.setItem('peakSpeedUnit', this.peakSpeedUnitSelected);
            storeSelectedSquadIds(this.squadsSelected);
        },
        cookieConsentAccept() {
            localStorage.setItem('cookie:accepted', true);
            this.$root.cookieConsent = true;
        },
        cookieConsentReject() {
            localStorage.setItem('cookie:accepted', false);
            this.$root.cookieConsent = false;
        }

    },
    async mounted() {
        await this.refreshData();
        console.log("topnav mounted");
    },
    computed: {
        currentPath() {
            return this.$route.path;
        },
        dropdownHtml() {
            // build the custom html for the dropdown
            // this uses the sanitize function to prevent XSS attacks
            const defaultAvatar = "'https://www.befunky.com/images/social-sharing/AurpBtf-1646823099667971.jpg?auto=webp'";

            return '<img src="' +
                this.sanitize(UserData.clubBadge()) +
                '" width="40" height="40" alt="Team logo"  class="customer-avatar" onerror="this.onerror=null;this.src= ' +
                this.sanitize(defaultAvatar)
                + '" >' +
                this.sanitize(UserData.displayName());
        },
    },
};
</script>