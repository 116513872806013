import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { errorHandler } from "@/components/ErrorHandler";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.localStorage, // Use localStorage for persistence
	key: "config",
});

const state = {
	sessionTypes: null,
	positions: null,
};

const mutations = {
	setSessionTypes(state, data) {
		state.sessionTypes = data;
	},
	setPositions(state, data) {
		state.positions = data;
	},
};

const actions = {
	async loadConfig({ commit }, context) {
		const response = await context.$root.webApiGet("/config");
		if (response.status === 200) {
			const { sessionTypes, positions } = response.data;
			commit("setSessionTypes", sessionTypes);
			commit("setPositions", positions);
		} else {
			errorHandler.error(response, context);
		}
	},
};

export default new Vuex.Store({
	state,
	mutations,
	actions,
	plugins: [vuexLocal.plugin],
});
