<template>
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="margin-left:90px;">
        <SessionHeader />
        <MissingDataText v-if="!hasData" message="KPI Reporting Unavailable" />
        <div id="content" class="py-2 px-3" v-else>
            <div style="display: flex; align-items: center">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h1 id="session-header" class="pb-4">
                                <span v-if="sessionIsAMatch">{{ customerName }} vs {{ oppositionName }} </span>
                                <span v-else>{{ sessionTitle }}</span>
                                <div class="pill ml-4" :style="{ borderColor: sessionTypeColour }">
                                    {{ sessionType }}
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-around mx-auto">
                    <RadioButton :id="'data-overview-type-radio-group'" :modal="selectedDataOverviewType"
                        :options="dataOverviewOptions" :name="'kpi-options'"
                        @update-modal="selectedDataOverviewType = $event" label="Data Overview" :task="updateTableData"
                        v-if="false" />

                    <RadioButton :id="'data-type-radio-group'" :modal="selectedDataType" :options="dataTypeOptions"
                        label="Data Type" :name="'kpi-options'" @update-modal="selectedDataType = $event"
                        :task="updateTableData" />
                </div>
            </div>

            <div class="px-3 mx-auto">
                <KPITable :data="tableData" columnKey="playerId" headingKey="name" :isPlayerTable=true
                    :kpiGroups="kpiGroups" />
            </div>
        </div>
    </div>
</template>

<script>
import KPITable from './KPITable.vue';
import { UserData } from "@/components/UserData";
import { errorHandler } from "@/components/ErrorHandler";
import ReportingKpiGroups from '@/utils/ReportingKpiGroups';
import store from '@/store/session';

export default {
    components: {
        KPITable
    },
    data() {
        return {
            customerId: UserData.customerId(),
            sessionId: this.$route.params.id, 
            selectedDataOverviewType: 'total',
            dataOverviewOptions: [
                { text: 'Total', value: 'total' },
                { text: 'In Play', value: 'inPlay' },

            ],
            selectedDataType: 'data',
            dataTypeOptions: [
                { text: 'Data', value: 'data' },
                { text: 'Percentile', value: 'percentile' }
            ],
            data: [],
            hasData: false,
            tableData: [],
            kpiGroups: ReportingKpiGroups,
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async getData() {
            const response = await this.$root.webApiGet(
                `/reporting?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                this.data = response.data;
                this.hasData = this.data.length > 0;
            } else {
                errorHandler.error(response, this);
            }
        },
        createValueAndPercentileObject(value, percentile) {
            const formattedValue = value === null ? "-" : value;
            const formattedPercentile = percentile === null ? "-" : Math.round(percentile);
            return {
                value: this.selectedDataType === 'data' ? formattedValue : formattedPercentile,
                percentile: formattedPercentile
            };
        },
        updateTableData() {
            this.tableData = [];
            for (let i = 0; i < this.data.length; i++) {
                const { player, sportlightMatchTimeM, wellnessKpis, highResolutionDistanceByThreshold,
                    highResolutionDistancePercentileByThreshold, totalDistanceM, totalDistanceMPercentile } = this.data[i];
                const matchType = 'FULL_MATCH'; // this will be dynamic and can be moved when we add in-play, in/out of possession filters
                const retrieveKpi = (kpiType, threshold) => {
                    // Retrieve the KPI value/percentile if it exists or set it to null if undefined or missing
                    // Use nullish coalescing (??) to ensure 0 is treated as a valid value and not replaced with null
                    const value = highResolutionDistanceByThreshold?.[matchType]?.[kpiType]?.[threshold] ?? null;
                    const percentile = highResolutionDistancePercentileByThreshold?.[matchType]?.[kpiType]?.[threshold] ?? null;
                    return this.createValueAndPercentileObject(value, percentile);
                };
                
                this.tableData.push({
                    playerId: player.playerId,
                    shirtNumber: player.shirtNumber,
                    name: player.name,
                    position: player.position,
                    positionId: player.positionSortOrder,
                    matchTime: sportlightMatchTimeM,
                    fatigueFlag: wellnessKpis.fatigueFlagValue,
                    gameDemandScore: wellnessKpis.gameDemandValue,
                    hamstringRisk: {
                        hamstringRisk: wellnessKpis.hamstringRisk,
                        hamstringRiskScore: wellnessKpis.hamstringRiskScore
                    },
                    distanceOver85WR: retrieveKpi('WORK_RATE', '85.00'),
                    distanceOver100WR: retrieveKpi('WORK_RATE', '100.00'),
                    distanceOver110WR: retrieveKpi('WORK_RATE', '110.00'),
                    distanceOver115WR: retrieveKpi('WORK_RATE', '115.00'),
                    distanceOver120WR: retrieveKpi('WORK_RATE', '120.00'),
                    totalDistance: this.createValueAndPercentileObject(totalDistanceM, totalDistanceMPercentile),
                    distanceOver17kmph: retrieveKpi('SPEED', '17.00'),
                    distanceOver20kmph: retrieveKpi('SPEED', '20.00'),
                    distanceOver23kmph: retrieveKpi('SPEED', '23.00'),
                    distanceOver25_5kmph: retrieveKpi('SPEED', '25.50'),
                    distanceOver27_5kmph: retrieveKpi('SPEED', '27.50'),
                    distanceOver29kmph: retrieveKpi('SPEED', '29.00'),
                    distanceOver30_5kmph: retrieveKpi('SPEED', '30.50'),
                    distanceOver31_5kmph: retrieveKpi('SPEED', '31.50'),
                    accelerationOver2mps2: retrieveKpi('ACCELERATION', '2.00'),
                    accelerationOver2_5mps2: retrieveKpi('ACCELERATION', '2.50'),
                    accelerationOver3mps2: retrieveKpi('ACCELERATION', '3.00'),
                    accelerationOver3_5mps2: retrieveKpi('ACCELERATION', '3.50'),
                    accelerationOver4mps2: retrieveKpi('ACCELERATION', '4.00'),
                    accelerationOver6mps2: retrieveKpi('ACCELERATION', '6.00'),
                    decelerationUnder2mps2: retrieveKpi('DECELERATION', '-2.00'),
                    decelerationUnder2_5mps2: retrieveKpi('DECELERATION', '-2.50'),
                    decelerationUnder3_25mps2: retrieveKpi('DECELERATION', '-3.25'),
                    decelerationUnder4mps2: retrieveKpi('DECELERATION', '-4.00'),
                    decelerationUnder4_75mps2: retrieveKpi('DECELERATION', '-4.75'),
                    decelerationUnder5_5mps2: retrieveKpi('DECELERATION', '-5.50'),
                });
                // Sort the data by positionId first then by shirtNumber
                this.tableData.sort((a, b) => {
                    if (a.positionId === b.positionId) {
                        return a.shirtNumber - b.shirtNumber;
                    }
                    return a.positionId - b.positionId;
                });
            }
        },
        generateRandomKPI() {
            const maxValue = this.selectedDataType === 'percentile' ? 100 : 15000;
            return {
                value: Math.floor(Math.random() * maxValue),
                percentile: Math.floor(Math.random() * 101),
            };
        },
    },
    computed: {
        session() {
            return store.state.session.session;
        },
        customerName() {
            return this.session.customerName;
        },
        oppositionName() {
            return this.session.opposition.customerName;
        },
        sessionType() {
            return this.session.type;
        },
        sessionTypeColour() {
            return this.session.typeColour;
        },
        sessionTitle() {
            return this.session.title;
        },
        sessionIsAMatch() {
            return this.session.sessionType.sessionIsAMatch;
        }
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getData();
            this.updateTableData();
            console.log("Reporting.vue mounted");
            this.$root.newPageView("Reporting Page", UserData.userName());
        });
    },
};
</script>